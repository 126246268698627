export default {
  async loadUnits(context, payload) {
    if (!payload.forceRefresh && !context.getters.unitsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURLV2 + "/units";
    
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const units = {
      // _1AR: {},
      // _1FI: {},
    };

    let plotOfWeekTemp = null;

    responseData.data.forEach(function(unit) {
      // switch (unit.property_building.id) {
      //   case 1:
      //     units._1FI[unit.id] = unit;
      //     break;
      //   case 2:
      //     units._1AR[unit.id] = unit;
      //     break;
      // }
      //if(unit.property_building.name != "C")
      
      units[unit.id] = unit;

      if(unit.plot_of_the_week == 1)
      {
        plotOfWeekTemp = unit;
        //console.log("** PLOT OF WEEK FOUND ** -- "+plotOfWeekTemp.id);
      }
       
    });

    

   

    context.commit("setPlotOfWeek", plotOfWeekTemp);
    context.commit("setUnits", units);
    context.commit("setUnitsFetchTimestamp");
  },

  setUnit(context, payload) {
    context.commit("setUnit", payload);
  },

  /*----------------
    PROPERTIES
    ----------------*/

  async loadStatuses(context, payload) {
    if (!payload.forceRefresh && !context.getters.statusesShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/status";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const statuses = [];

    responseData.data.forEach(function(status) {
      //if (status.id === 1 || status.id === 6 || status.id === 7) {
        statuses.push(status);
      //}
    });

    context.commit("setStatuses", statuses);
    context.commit("setStatusesFetchTimestamp");
  },

  async loadFloors(context, payload) {
    if (!payload.forceRefresh && !context.getters.floorsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/floor";
    //const url = context.rootState.publicApiURL + "/categories";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const floors = [];

    responseData.data.forEach(function(floor) {
      
        floors.push(floor);
    });

    context.commit("setFloors", floors);
    context.commit("setFloorsFetchTimestamp");
  },

  async loadBedrooms(context, payload) {
    if (!payload.forceRefresh && !context.getters.bedroomsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/bedroom";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const bedrooms = [];

    responseData.data.forEach(function(bedroom) {
      //console.log("--->>beds="+bedroom.id+"<<");
      if(bedroom.id != 4)
        bedrooms.push(bedroom);
    });

    context.commit("setBedrooms", bedrooms);
    context.commit("setBedroomsFetchTimestamp");
  },

  async loadCategories(context, payload) {
    if (!payload.forceRefresh && !context.getters.categoriesShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/category";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const categories = [];

    responseData.data.forEach(function(category) {
        if(category.enabled)
          categories.push(category);
    });

    context.commit("setCategories", categories);
    context.commit("setCategoriesFetchTimestamp");
  },

  async loadBathrooms(context, payload) {
    if (!payload.forceRefresh && !context.getters.bathroomsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/bathroom";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const bathrooms = [];

    responseData.data.forEach(function(bathroom) {
      bathrooms.push(bathroom);
    });

    context.commit("setBathrooms", bathrooms);
    context.commit("setBathroomsFetchTimestamp");
  },

  async loadAspects(context, payload) {
    if (!payload.forceRefresh && !context.getters.aspectsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/aspect";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const aspects = [];

    responseData.data.forEach(function(aspect) {
      // dont show SE, SW, NE,NW
      if(aspect.enabled)
        aspects.push(aspect);
     
    });

    context.commit("setAspects", aspects);
    context.commit("setAspectsFetchTimestamp");
    },

    async loadViews(context, payload) {
    if (!payload.forceRefresh && !context.getters.viewsShouldUpdate) {
      return;
    }
    
    const url = context.rootState.publicApiURL + "/units/properties/view";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const views = [];

    responseData.data.forEach(function(view) {
      views.push(view);
    });

    context.commit("setViews", views);
    context.commit("setViewsFetchTimestamp");
  },

  async loadBuildings(context, payload) {

    if (!payload.forceRefresh && !context.getters.buildingsShouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/units/properties/building";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });
    
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const buildings = [];

    responseData.data.forEach(function(b) {
    //if(b.id > 18)
      if(b.enabled)
        buildings.push(b);

      //console.log("adding Buildings"+b.name);
    });

    context.commit("setBuildings", buildings);
    context.commit("setBuildingsFetchTimestamp");
  },
  
  /*----------------
  FAVOURITES
  ----------------*/

  addFavourite(context, payload) {
    const favourites = context.getters.favourites;
    favourites.push(payload);

    context.commit("setFavourites", favourites);
  },
  removeFavourite(context, payload) {
    const favourites = context.getters.favourites;
    // const newFavourites = favourites.filter(favourite => favourite !== payload);
    const newFavourites = favourites.filter((favourite) => !favourite[payload]);

    context.commit("setFavourites", newFavourites);
  },
  clearFavourites(context) {
    context.commit("setFavourites", []);
  },
};
