export default {
  async loadLanguages(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    //console.log("url="+context.rootState.publicApiURL+"<");

    const url = context.rootState.publicApiURL + "/languages";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const languages = [];
    const textLanguages = {};

    responseData.data.forEach(function(language) {
      languages.push(language);
      textLanguages[language.id] = {};
    });
    
    context.commit("setLanguages", languages);
    context.commit("setFetchTimestamp");

    context.commit("categories/setText", textLanguages,  { root: true });
  },

  tryLanguage(context) {
    const id = localStorage.getItem("lang_id");
    const code = localStorage.getItem("lang_code");

    if (id && code) {
      context.commit("setLanguage", id);
    } else {
      context.commit("setLanguage", 1);
    }
  },

  changeLanguage(context, payload) {
    context.commit("setLanguage", payload);
  },
};
