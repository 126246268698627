export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },
  async auth(context, payload) {
    let url = context.rootState.privateApiURL + "/auth/token";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    localStorage.setItem("token", responseData.access_token);

    context.commit("setUser", {
      token: responseData.access_token
    });
  },
  tryLogin(context, payload) {
    const token = localStorage.getItem("token");
    if (token) {
      context.commit("setUser", {
        token: token
      });
    } else {
      if (payload.tk) {
        if (context.dispatch("tryToken", payload)) {
          context.commit("setUser", {
            token: payload.tk,
          }, payload);
          localStorage.setItem("token", payload.tk);
        }
      }
    }
  },
  async tryToken(context, payload) {
    const url = context.rootState.privateApiURL + "/languages";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + payload.tk,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      return false;
    }
    return responseData;
  },
  logout(context) {
    localStorage.removeItem("token");

    context.commit("setUser", {
      token: null
    });
  }
}