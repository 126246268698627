export default {
  visibility(context) {
    context.commit('setVisibility');
  },
  page(context, payload) {
    context.commit('setPage', {
      payload
    });
  },
  modelLoading(context) {
    context.commit('setModelLoading');
  },
  showStart(context) {
    context.commit('setShowStart');
  },
};