<template>
  <div class="about relative" v-if="text && images">
    <!-- <base-dialog
      :show="!!sendBooking"
      title="Booking Sent!"
      @close="handleSendBooking"
      fixed
    >
    </base-dialog> -->
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"
    ></the-header>

    <div class="headerPic" v-if="windowWidth > 768">
      <img
        class="headerPicImg w-full"
        :src="images['homeScreenMainImage'].fourK"
      />
    </div>
    <div class="headerPic" v-else>
      <img
        class="headerPicImg w-full"
        :src="images['homeScreenMainImageMobile'].extra_large"
      />
    </div>

    <base-content>
      <base-page-heading
        :title="text[currentLanguage]['contact-main-title']"
        :copy="text[currentLanguage]['contact-main-address']"
      ></base-page-heading>

    </base-content>

    <div class="video">
      <!-- <img class="w-full" :src="images['contactMapImage'].extra_large" alt="" /> -->
    </div> 

    <div class="bookingBox text-white py-10">
      <base-content>
        <div class="mb-12 pt-10">
          <h2
            class="text-center mb-16 uppercase"
            style="color: var(--main-key-color)"
          >
            {{ text[currentLanguage]["booking-label-title"] }}
          </h2>
           <!-- @booking="saveBooking" -->
          <form-booking
            theme="light"
            button="flat-white-border"
            :text="text"
          ></form-booking>
        </div>
      </base-content>
    </div>
    <the-footer theme="dark"></the-footer>
  </div>
</template>

<script>
import FormBooking from "../components/forms/FormBooking.vue";

export default {
  name: "Contact",
  components: {
    FormBooking,
  },
  data() {
    return {
      sendBooking: false,
    };
  },
  emits: ["update"],
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  mounted() {
    
    this.$emit("update", true);
  },
  methods: {

    // showSentDialog() {
    //   this.sendBooking = true;
    // },
    // handleSendBooking() {
    //   this.sendBooking = false;
    // },
    // saveBooking(data) {
    //   this.$store.dispatch("booking/book", data);
    //   this.sendBooking = true;
    // },

  },
  watch: {

    // sendBooking(state) {
    //   if (state) {
    //     document.body.classList.add("overflow-hidden");
    //   } else {
    //     document.body.classList.remove("overflow-hidden");
    //   }
    // },
    
  },
};
</script>

<style scoped>

.about{
  z-index: 2;
}
.headerPic {
  padding-top: 8rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
}

.video {
  width: 100%;
  padding-top: 5rem;
}

.bookingBox {
  background-color: var(--main-menu-bgcolor);
}

.img-col {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.address-container {
  border-top: 3px solid #e94a3f;
  padding-top: 6rem;
  margin-bottom: 5rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  text-transform: uppercase;
  font-weight: 700;
}
@media (min-width: 720px) {
  h2 {
    font-size: 3rem;
  }
}
</style>
