<template>
  <div class="heading">
    <h1 v-html="title"></h1>
    <div class="copy" v-html="copy"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    copy: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: var(--main-key-color);
  font-family: "Proxima Nova", sans-serif;
}

.heading {
  /* padding-top: 15rem; */

  margin-bottom: 0rem;
  padding-left: 3rem;
  padding-right: 3rem;
  font-weight: 500;
   color: var(--main-menu-subColor);
}

.copy {
  text-align: center;
  color: var(--main-key-subcolor);
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  font-family: "Proxima Nova Lt", sans-serif;
  /* max-width: 30rem; */
}

@media (min-width: 720px) {
  h1 {
    font-size: 3rem;
    line-height: 60px;
    font-weight: 500;

    background: -webkit-linear-gradient(
      var(--main-key-colorDark),
      var(--main-key-colorLight)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Proxima Nova", sans-serif;
  }
  .heading {
    /* padding-top: 15rem; */
    /* margin-bottom: 4rem; */
    padding-left: 10rem;
    padding-right: 10rem;
    font-family: "Proxima Nova", sans-serif;
  }

  .copy {
    font-size: 1.75rem;
    line-height: 34px;
    font-family: "Proxima Nova Lt", sans-serif;
  }
}

/* @media (min-width: 1024px) {
 
  .heading {
   
    padding-left:15rem;
    padding-right:15rem;
   
  }
}

 @media (min-width: 1280px) {
 
  .heading {
  
    padding-left:15rem;
    padding-right:15rem;
  
  }
}

 @media (min-width: 1536px) {
 
  .heading {
   
    padding-left:15rem;
    padding-right:15rem;
  
  }
} */

/* @media (min-width: 1960px) {
  h1 {
    font-size: 2rem;
    line-height: 5rem;
  }
}  */
</style>
