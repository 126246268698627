<template>
  <div class="relative about bg-white" v-if="text && images">
  
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"
    ></the-header>

    <div class="headerPic z-[1]" v-if="$mq !== 'sm'">
      <img
        class="headerPicImg w-full"
        :src="images['homeScreenMainImage'].fourK"
      />
    </div>
    <div class="headerPic z-[1]" v-else>
      <img
        class="headerPicImg w-full"
        :src="images['homeScreenMainImageMobile'].extra_large"
      />
    </div>

    <base-page-heading
      :title="text[currentLanguage]['home_strap_text']"
      :copy="text[currentLanguage]['home_col1']"
    ></base-page-heading>

    <div class="twoPicHolder mt-20">
      <div class="twoPic1">
        <img :src="images['homeScreen_col1_pic'].large" alt="" />
        <div class="picHolderText" v-html="text[currentLanguage]['homeScreen_col2_text']"></div>
      </div>
      <div class="twoPic2">
        <img :src="images['homeScreen_col2_pic'].large" alt="" />
        <div class="picHolderText" v-html="text[currentLanguage]['homeScreen_col1_text']"></div>
         <!-- {{ text[currentLanguage]["homeScreen_col1_text"] }} -->
      </div>
    </div>

    <button v-if="!videoInPlay" class="video">
      <img
        class="w-full"
        :src="images['homeScreenVideoImage'].fourK"
        alt=""
        @click="this.showVideo"
      />
    </button>

    <video v-else class="video" autoplay muted loop playsinline controls>
      <source
        src="../assets/1708814_FNH_Brentwood_Acres_Main_video_FINAL_1_1.mp4"
        type="video/mp4"
      />
    </video>


    <div class="infoPara">
      <div class="p1">{{ text[currentLanguage]["home_para1"] }}</div>
      <div class="p2" v-html="text[currentLanguage]['home_para2']"></div>
    </div>

    <base-gallery galleryCategoryString="gallery-home-page"> </base-gallery>

    <the-footer theme="clear"></the-footer>

    
  </div>
</template>

<script>



export default {
  name: "About",
  components: {
   
  },
  data() {
    return {
     
      galleryVisible: false,
      galleryIndex: 2,
      videoInPlay: false,
    };
  },
  emits: ["update"],
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
  
    galleryButLeftImg() {
      return "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI1IiBjeT0iMjUiIHI9IjI0LjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCAyNSAyNSkiIHN0cm9rZT0iIzExMTAwRCIvPg0KPHBhdGggZD0iTTI3IDIxTDIyIDI1LjQyNzRMMjcgMjkuODU0NyIgc3Ryb2tlPSIjMTExMDBEIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCjwvc3ZnPg0K";
    },
    galleryButRightImg() {
      return "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI1IiBjeT0iMjUiIHI9IjI0LjUiIHN0cm9rZT0iIzExMTAwRCIvPg0KPHBhdGggZD0iTTIzIDMwLjAyODNMMjggMjUuNjAxTDIzIDIxLjE3MzYiIHN0cm9rZT0iIzExMTAwRCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8L3N2Zz4NCg==";
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
    
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  mounted() {

    this.$emit("update", true);
  },
  methods: {
    showVideo() {
      this.videoInPlay = true;
    },
  },
  watch: {
   
  },
};
</script>

<style>

.about{
  z-index:2;
}

.gallery {
  max-height: 550px;
  flex-wrap: nowrap;
  flex-direction: row;
  display: flex;
  height: 550px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 3rem;
}

.gallery img {
  margin-right: 3rem;
  height: 100%;
  width: auto;
  display: inline-block;
  max-width: unset;
}

.headerPic {
  width: 100%;
  padding-top: 5rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 2rem;
}

.twoPicHolder {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
  color: var(--main-key-color);
  line-height: 1rem;
}

.picHolderText {
  font-size: 1rem;
  color: var(--main-menu-subColor);
  line-height: 2rem;
  font-weight: 300;
  padding-top: 2rem;
}

.twoPic1 {
  padding-top: 20%;
}

.twoPic2 {
  padding-top: 20%;
}

.video {
  width: 100%;
  padding-top: 6rem;
}

.infoPara {
  width: 100%;
  padding-top: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p1 {
  text-align: center;
  color: var(--main-key-color);
  font-size: 1.75rem;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Proxima Nova", sans-serif;
}

.p2 {
  padding-top: 4rem;
  text-align: center;
  color: var(--main-key-subColor);
  font-size: 1.75rem;
  line-height: 32px;
  font-weight: 400;
  font-family: "Proxima Nova Lt", sans-serif;
}

.vel-img-title {
  font-size: 1rem;
  color: white;
}

@media (min-width: 720px) {

  .headerPic {
    width: 100%;
    padding-top: 9rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
  }

  .infoPara {
    width: 100%;
    padding-top: 5rem;
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .twoPicHolder {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    display: grid;
    position: relative;
    grid-template-columns: repeat(13, 1fr);
  }

  .twoPic1 {
    grid-column: 8 / span 6;
    grid-row: 1;
    padding-top: 20%;
  }

  .twoPic2 {
    grid-row: 1;
    grid-column: 1 / span 6;
    z-index: 1;
    padding-top: 0%;
  }

  .p1 {
    font-size: 28px;
    line-height: 48px;
  }

  .p2 {
    font-size: 28px;
    line-height: 48px;
  }
}

.page-head-image {
  background-size: cover !important;
  background-position: center !important;
  margin-bottom: 8rem;
}

.page-head-button-container {
  text-align: right;
}

.page-heading {
  padding-bottom: 16rem;
}

@media (min-width: 720px) {
  .page-heading {
    padding-bottom: 37rem;
  }
}

@media (min-width: 1024px) {
  .page-heading {
    padding-bottom: 30rem;
  }
}

@media (min-width: 1280px) {
  .page-heading {
    padding-bottom: 25rem;
  }
}

@media (min-width: 1536px) {
  .page-heading {
    padding-bottom: 40rem;
  }
}
</style>
