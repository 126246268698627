export default {
  async book(context, data) {

    //console.log(context+"booing form data="+data);

    const bookData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      date: data.date,
      time: data.time,
      message: data.message,
    };

    const url = context.rootState.publicApiURL + "/book";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
      body: JSON.stringify(bookData)
    });

    if (!response.ok) {
      // error ...
    }
  },
};