export default {
  setUnits(state, payload){
    state.units = payload;
  },
  setUnitsFetchTimestamp(state) {
    state.unitsLastFetch = new Date().getTime();
  },
  setStatuses(state, payload){
    state.statuses = payload;
  },
  setStatusesFetchTimestamp(state) {
    state.statusesLastFetch = new Date().getTime();
  },
  setFloors(state, payload){
    state.floors = payload;
  },
  setPlotOfWeek(state, payload){
    state.plotOfWeek = payload;
  },
  setFloorsFetchTimestamp(state) {
    state.floorsLastFetch = new Date().getTime();
  },
  setBedrooms(state, payload){
    state.bedrooms = payload;
  },
  setBedroomsFetchTimestamp(state) {
    state.bedroomsLastFetch = new Date().getTime();
  },
  setCategories(state, payload){
    state.categories = payload;
  },
  setCategoriesFetchTimestamp(state) {
    state.categoriesLastFetch = new Date().getTime();
  },
  setBathrooms(state, payload){
    state.bathrooms = payload;
  },
  setBathroomsFetchTimestamp(state) {
    state.bathroomsLastFetch = new Date().getTime();
  },
  setAspects(state, payload){
    state.aspects = payload;
  },
  setAspectsFetchTimestamp(state) {
    state.aspectsLastFetch = new Date().getTime();
  },
  setViews(state, payload){
    state.views = payload;
  },
  setViewsFetchTimestamp(state) {
    state.viewsLastFetch = new Date().getTime();
  },
  setBuildings(state, payload){
    state.buildings = payload;
  },
  setBuildingsFetchTimestamp(state) {
    state.buildingsLastFetch = new Date().getTime();
  },
  setUnit(state, payload) {
    state.currentUnit = payload;
  },
  setFavourites(state, payload) {
    state.favourites = payload;
  },
}