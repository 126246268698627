export default {
  units: (state) => (status = 0) => {
    if (status !== 0) {
      const units = state.units;
      const filtered = [];
      Object.keys(units).forEach(function(prop) {
        if (units[prop].property_status.id === 1) {
          filtered.push(units[prop]);
        }
      });
      return filtered;
    }
    return state.units;
  },

  // units: (state) => (status = 0) => {

  //   if (status !== 100) {
  //     const units = state.units;
  //     const filtered = [];
  //     Object.keys(units).forEach(function(prop) {
     
  //      filtered.push(units[prop]);

  //     });

  //     return filtered;
  //   }

  //   return state.units;
  // },

  unitsShouldUpdate(state) {
    const lastFetch = state.unitsLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  statuses(state){
    return state.statuses;
  },
  statusesShouldUpdate(state) {
    const lastFetch = state.statusesLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  floors(state){
    return state.floors;
  },
  plotOfWeek(state){
    return state.plotOfWeek;
  },
  floorsShouldUpdate(state) {
    const lastFetch = state.floorsLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  bedrooms(state){
    return state.bedrooms;
  },
  bedroomsShouldUpdate(state) {
    const lastFetch = state.bedroomsLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },

  categories(state){
    return state.categories;
  },
  categoriesShouldUpdate(state) {
    const lastFetch = state.categoriesLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },

  bathrooms(state){
    return state.bathrooms;
  },
  bathroomsShouldUpdate(state) {
    const lastFetch = state.bathroomsLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  aspects(state){
    return state.aspects;
  },
  aspectsShouldUpdate(state) {
    const lastFetch = state.aspectsLastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  views(state){
    return state.views;
  },
  viewsShouldUpdate(state) {
    const lastFetch = state.viewsLastFetch;
    
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  buildings(state){
    return state.buildings;
  },
  buildingsShouldUpdate(state) {
    const lastFetch = state.buildingsLastFetch;

    if (!lastFetch) {
      return true;
    }
    //const currentTimeStamp = new Date().getTime();
    return true;//(currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  currentUnit(state) {
    return state.currentUnit;
  },
  favourites(state) {
    return state.favourites;
  },
  hasFavourites(state) {
    return state.favourites.length;
  },
}