export default {
  infoPanel(state) {
    return state.infoPanel;
  },
  modelLoading(state) {
    return state.modelLoading;
  },
  // filter: (state) => (scheme, filter) => {
  //   return state[scheme][filter];
  // }
  filter: (state) => (filter) => {
    //console.log("st="+state+" filter="+filter+" ans="+state[filter]+"<");
    return state[filter];
  }
}