export default {
  async event(context, data) {
    const eventData = {
      type: data.type,
      id: data.id,
    };

    const url = context.rootState.publicApiURL + "/event";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(eventData)
    });

    if (!response.ok) {
      // error ...
    }
  },
};