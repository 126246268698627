<template>
 
  <div ref="gallery" id="gallery" class="gallery mt-20">
    <img
      v-for="imageObj in galleryArray"
      :key="imageObj"
      :src="imageObj.src"
      @click="showGallery(imageObj.id)"
    />
  </div>

  <base-content>
    <div class="butImages flex flex row justify-end mb-24">
      <button
        @mousedown="startG(-1)"
        @mouseleave="stopG"
        @mouseup="stopG"
        @touchstart="startG(-1)"
        @touchend="stopG"
        @touchcancel="stopG"
        class="pr-4"
      >
        <img :src="galleryButLeftImg" />
      </button>
      <button
        @mousedown="startG(1)"
        @mouseleave="stopG"
        @mouseup="stopG"
        @touchstart="startG(1)"
        @touchend="stopG"
        @touchcancel="stopG"
        class="pr-4"
      >
        <img :src="galleryButRightImg" />
      </button>
    
    </div>
  </base-content>

  <vue-easy-lightbox
    moveDisabled
    :visible="galleryVisible"
    :imgs="galleryArray"
    :index="galleryIndex"
    @hide="hideGallery()"
  >
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template v-slot:toolbar="{ toolbarMethods }">
      <div></div>
    </template>
  </vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import iconGalleryButLeft from "../../assets/iconGalleryButLeft.svg";
import iconGalleryButRight from "../../assets/iconGalleryButRight.svg";

export default {
  components: {
    VueEasyLightbox,
  },
  props: {
    galleryCategoryString: {
      type: String,
      required: true,
      default: "gallery-interior",
    },
  },
  data() {
    return {
      galleryVisible: false,
      galleryIndex: 2,
    };
  },

  computed: {
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    galleryArray() {
      try {
        const images = this.$store.getters["categories/images"];
        const gallery = [];
        var idCount = 0;
        var self = this;

        //console.log("---using gallery="+self.galleryCategoryString+"<");

        Object.keys(images).forEach(function (prop) {
         
          if (images[prop].category === self.galleryCategoryString) {
            gallery.push({
              src: images[prop].large,
              title: images[prop].caption,
              id: idCount,
            });
            idCount++;
          }
        });
        return gallery;
      } catch (error) {
        return null;
      }
    },
    galleryButLeftImg() {
      return iconGalleryButLeft;
      //return "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI1IiBjeT0iMjUiIHI9IjI0LjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCAyNSAyNSkiIHN0cm9rZT0iIzExMTAwRCIvPg0KPHBhdGggZD0iTTI3IDIxTDIyIDI1LjQyNzRMMjcgMjkuODU0NyIgc3Ryb2tlPSIjMTExMDBEIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCjwvc3ZnPg0K";
    },
    galleryButRightImg() {
      return iconGalleryButRight;
      //return "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxjaXJjbGUgY3g9IjI1IiBjeT0iMjUiIHI9IjI0LjUiIHN0cm9rZT0iIzExMTAwRCIvPg0KPHBhdGggZD0iTTIzIDMwLjAyODNMMjggMjUuNjAxTDIzIDIxLjE3MzYiIHN0cm9rZT0iIzExMTAwRCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8L3N2Zz4NCg==";
    },
  },
  mounted() {},
  methods: {
    showGallery(i) {
      this.galleryIndex = i;
      this.galleryVisible = true;
    },
    hideGallery() {
      this.galleryVisible = false;
    },
    startG(dir) {
      if (!this.interval) {
        this.interval = setInterval(() => this.galleryScrollButHitAction(dir));
      }
    },
    stopG() {
      clearInterval(this.interval);
      this.interval = false;
    },
    galleryScrollButHitAction(dir) {
      var g = document.getElementById("gallery");
      g.scrollLeft = g.scrollLeft + 5 * dir;
      // g.scrollIntoView(true);
    },
  },
};
</script>

<style scoped>

.butImages{
   filter: var(--main-filterColDark); 
}

.gallery {
  max-height: 550px;
  flex-wrap: nowrap;
  flex-direction: row;
  display: flex;
  height: 550px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 3rem;
}

.gallery img {
  margin-right: 3rem;
  height: 100%;
  width: auto;
  display: inline-block;
  max-width: unset;
}

@media (max-width: 720px) {
  .gallery {
    margin-left: calc((100vw - (720px - 3rem)) / 2);
  }
}
</style>

