import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
//import store from '../store/index.js';
import About from "../views/About.vue";
import Location from "../views/Location.vue";
import Finder from "../views/Finder.vue";
import Travel from "../views/Travel.vue";
import Rail from "../views/Rail.vue";
import ListView from "../views/ListView.vue";
import UnitDetails from "../views/UnitDetails.vue";
import Contact from "../views/Contact.vue";
import Favourites from "../views/Favourites.vue";
//import Login from "../views/Login.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Disclaimer from "../views/Disclaimer.vue";
import TermsConditions from "../views/TermsConditions.vue";


const routes = [
  {
    path: "/",
    name: "About",
    component: About,
    //meta: { requiresAuth: true }
  },
 
  {
    path: "/location",
    name: "Location",
    component: Location,
    //meta: { requiresAuth: true }
  },

  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
    //meta: { requiresAuth: true }
  },

  {
    path: "/rail",
    name: "Rail",
    component: Rail,
    //meta: { requiresAuth: true }
  },

  {
    path: "/travel",
    name: "Travel",
    component: Travel,
    //meta: { requiresAuth: true }
  },

  {
    path: "/finder/undefined",
    redirect: '/finder/3d',
    //meta: { requiresAuth: true },
  },

  {
    path: "/finder/3d",
    name: "Finder3D",
    component: Finder,
    //meta: { requiresAuth: true }
  },

  {
    path: '/finder/list',
    name: "FinderList",
    component: ListView,
    //meta: { requiresAuth: true }
  },

  {
    path: '/finder/:id',
    name: "FinderUnit",
    component: UnitDetails,
    props: true,
    //meta: { requiresAuth: true }
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    //meta: { requiresAuth: true }
  },

  {
    path: "/favourites",
    name: "Favourites",
    component: Favourites,
    //meta: { requiresAuth: true }
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },

  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: TermsConditions
  },

  //{ path: '/login', component: Login, meta: { requiresUnauth: true } },
  // { path: "/:notFound(.*)", redirect: "/" },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: process.env.IS_ELECTRON ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash
          });
        }, 1000);
      });
    }
    if (savedPosition) {
      return savedPosition;
    }
    return {
      left: 0,
      top: 0
    };
  }
});

// router.beforeEach(function(to, from, next) {
//   if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
//     next('/login');
//   } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
//     next('/');
//   } else {
//     next();
//   }
// });

export default router;
