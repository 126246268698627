export default {
  setVisibility(state) {
    state.infoPanel.visible = !state.infoPanel.visible;
  },
  setPage(state, payload) {
    state.infoPanel.page = payload;
  },
  setModelLoading(state) {
    state.modelLoading = false;
  },
  setShowStart(state) {
    state.infoPanel.showStart = false;
  },
  setFilter(state, payload) {
   
    //state[payload.scheme][payload.filter] = payload.value;
    state[payload.filter] = payload.value;
  }
};