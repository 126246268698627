<template>

  <div class="privacy-policy relative" v-if="text && images">
    <the-header v-if="text && images"
      :text="text"
      logoTheme="default"
      menuTheme="default"
    ></the-header>
    <base-content>
      <base-page-heading
        :title="text[currentLanguage]['terms-privacy-main-title']"
        class="text-blue mt-40"
      ></base-page-heading>
      <div
        v-html="text[currentLanguage]['terms-privacy-main-copy']"
        class="mb-10 pt-24"
      ></div>
    </base-content>
    <the-footer theme="clear"></the-footer>
  </div>
</template>

<script>
export default {
  name: "Privacy Policy",
  computed: {
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
};
</script>

<style scoped>
.privacy-policy{
  z-index:2;
}
</style>
