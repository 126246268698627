<template>

     <base-dialog
      :show="!!sendBooking"
      title="Booking Sent!"
      @close="handleSendBooking"
      fixed
    >
    </base-dialog>

  <form @submit.prevent="submitForm">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8">
      <div class="form-control">
        <input
          name="firstName"
          type="text"
          id="inputFirstName"
          v-model.trim="firstName.val"
          :class="theme"
          :placeholder="text[currentLanguage]['booking-placeholder-firstname']"
          required
        />
      </div>
      <div class="form-control">
        <input
          name="lastName"
          type="text"
          id="inputLastName"
          v-model.trim="lastName.val"
          :class="theme"
          :placeholder="text[currentLanguage]['booking-placeholder-lastname']"
          required
        />
      </div>
      <div class="form-control">
        <input
          name="email"
          type="email"
          id="inputEmail"
          v-model.trim="email.val"
          :class="theme"
          :placeholder="text[currentLanguage]['booking-placeholder-email']"
          required
        />
      </div>
      <div class="form-control">
        <input
          type="tel"
          id="inputTel"
          name="phone"
          pattern="[0-9]*"
          v-model.trim="phone.val"
          :class="theme"
          :placeholder="text[currentLanguage]['booking-placeholder-phone']"
          required
        />
      </div>
      <div class="form-control">
        <label for="inputDate">{{
          text[currentLanguage]["booking-label-date"]
        }}</label>
        <input
          type="date"
          id="inputDate"
          name="date"
          v-model.trim="date.val"
          :class="theme"
          required
        />
      </div>
      <div class="form-control">
        <label for="inputTime">{{
          text[currentLanguage]["booking-label-time"]
        }}</label>
        <input
          type="time"
          id="inputTime"
          name="time"
          v-model.trim="time.val"
          :class="theme"
          required
        />
      </div>
    </div>
    <div>
      <!-- <label for="inputMessage">{{
        text[currentLanguage]["booking-label-message"]
      }}</label> -->
      <textarea
        id="inputMessage"
        name="message"
        rows="5"
        v-model.trim="message.val"
        :placeholder="text[currentLanguage]['booking-label-message']"
        :class="theme"
        required
      ></textarea>
    </div>
    <div class="text-center pt-10 uppercase">
      <base-button :theme="button" width="width-50" style="text-transform: uppercase;">{{
        text[currentLanguage]["booking-button-submit"]
      }}</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: "light",
    },
    button: {
      type: String,
      required: false,
      default: "flat",
    },
    text: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      sendBooking: false,

      firstName: {
        val: "",
        isValid: true,
      },
      lastName: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      date: {
        val: "",
        isValid: true,
      },
      time: {
        val: "",
        isValid: true,
      },
      message: {
        val: "",
        isValid: true,
      },
      formIsValid: true,
    };
  },
  //emits: ["booking"],
  computed: {
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
  methods: {

    showSentDialog() {
      this.sendBooking = true;
    },

    handleSendBooking() {
      this.sendBooking = false;
    },

    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      
      if (this.firstName.val === "") {
        this.firstName.isValid = false;
        this.formIsValid = false;
      }
      if (this.lastName.val === "") {
        this.lastName.isValid = false;
        this.formIsValid = false;
      }
      if (this.phone.val === "") {
        this.phone.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val === "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.date.val === "") {
        this.date.isValid = false;
        this.formIsValid = false;
      }
      if (this.time.val === "") {
        this.time.isValid = false;
        this.formIsValid = false;
      }
      if (this.message.val === "") {
        this.time.isValid = false;
        this.formIsValid = false;
      }
    },
    submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      const formData = {
        firstName: this.firstName.val,
        lastName: this.lastName.val,
        phone: this.phone.val,
        email: this.email.val,
        date: this.date.val,
        time: this.time.val,
        message: this.message.val,
      };

      //this.$emit("booking", formData);
        // sendBooking(state) {
    //   if (state) {
    //     document.body.classList.add("overflow-hidden");
    //   } else {
    //     document.body.classList.remove("overflow-hidden");
    //   }
    // },

      this.$store.dispatch("booking/book", formData);
      this.sendBooking = true;

    },
  },
};
</script>

<style scoped>
form {
  margin: 0;
  padding: 0;
}

.form-control {
  margin: 0.5rem 0 1.5rem;
}

label {
  font-size: 1rem;
  font-weight: 300;
  /* font-family: "Inter-Light", sans-serif; */
  padding-left: 1.25rem;
  
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font: inherit;
  border: 1.5px solid #fff;
  background-color: transparent;
  padding: 0.5rem 1rem;
  font-size: 12px;
  line-height: 24px;
   text-transform: uppercase;
}

input {
  border-radius: 0px;
}

textarea {
  border-radius: 0px;
}

input:focus,
textarea:focus {
  /* border-color: #ffffff; */
  border: 2px solid #ffffff;
  /* background-color: #ffa880; */
  outline: none;
}

.dark {
  color: inherit;
}

input.dark,
textarea.dark {
  border: 2px solid #3b4559;
}

.light {
  color: #fff;
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  /* -webkit-appearance: textfield;
    -moz-appearance: textfield; */
  min-height: 2rem;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  /* font-family: "Inter-Light", sans-serif; */
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  /* font-family: "Inter-Light", sans-serif; */
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  /* font-family: "Inter-Light", sans-serif; */
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  /* font-family: "Inter-Light", sans-serif; */
}
</style>
