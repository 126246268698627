<template>
  <div class="userdetails relative" v-if="unit && text && images">
  
    <base-dialog
      :show="!!showShare"
      :title="text[currentLanguage]['share-main-title']"
      @close="handleShare"
      fixed
    >
      <div class="text-center">
       
      </div>
      <div class="my-10 text-center">
        <input
          class="copy-input"
          v-model="currentPage"
          ref="currentPage"
          readonly
        />
      </div>
      <div class="text-center">
        <base-button
          theme="outline-white"
          width="width-100"
          @click="copyLink"
          >{{ text[currentLanguage]["share-button-copylink"] }}</base-button
        >
      </div>
    </base-dialog>
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"

    ></the-header>

    <div class="headerPic" v-if="$mq != 'sm'">
      <img
        class="headerPicImg w-full"
        :src="images[unitSplash].twoK"
      />
    </div>
    <div class="headerPic" v-else>
      <img
        class="headerPicImg w-full"
        :src="images[unitSplashMobile].extra_large"
      />
    </div>

    <div class="overflow-hidden relative">
      <base-content>
      
        <div class="grid grid-cols-1 md:grid-cols-12 md:gap-20">
          <div @click="goBack" class="col-span-1 w-36">
            <button class="backArrowDiv pr-24">
              <img :src="backButImg" />
            </button>
          </div>

          <div class="heading col-span-5">
            <div class="mb-10">
              <h1>
                {{ text[currentLanguage]["unitMainName"] + " " + unit.name }}
              </h1>
              <h2 class="uppercase building-title mb-2">
                {{ unit.property_building.name }}
              </h2>
              <h4 v-if="unitDesc">
                <div
                  v-html="unitDesc"   
                ></div>
              </h4>
              <h4 v-else>
                <!--v-else v-html="text[currentLanguage]['unitMainDescription']">  -->  <!--text[currentLanguage]['unitMainDescription']"  -->
                <div v-html="text[currentLanguage]['unitMainDescription']"
                ></div>
              </h4>
              <div v-if="unit.price" class="mt-5 priceHolder">
                <div>
                  <!--v-if="unit.show_price"-->
                  <p class="inline mr-2">
                    <!-- {{ text[currentLanguage]["finder-label-pricesfrom"] }} -->
                    Prices from
                  </p>
                  <div class="inline">
                    {{
                      unit.property_currency.symbol + formatPrice(unit.price)
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div>
               
                <base-button
                  v-if="isFavourite"
                  @click="removeFavourite"
                  class="mr-3 mt-3 float-left clear-both md:clear-none"
                  theme="flat-white-border-sm"
                  width="width-auto-m"
                >
                  <img
                    class="heartImg"
                    :src="heartSolid" 
                  />
                  {{
                    text[currentLanguage]["finder-button-favourites-remove"]
                  }}</base-button
                >
                <!-- src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNC43ODc1IDIuMzA5NDJDMTQuNDMwNCAxLjk3MzA5IDE0LjAwNjMgMS43MDYyOSAxMy41Mzk1IDEuNTI0MjZDMTMuMDcyNyAxLjM0MjIzIDEyLjU3MjQgMS4yNDg1NCAxMi4wNjcyIDEuMjQ4NTRDMTEuNTYxOSAxLjI0ODU0IDExLjA2MTYgMS4zNDIyMyAxMC41OTQ4IDEuNTI0MjZDMTAuMTI4MSAxLjcwNjI5IDkuNzAzOTcgMS45NzMwOSA5LjM0Njc4IDIuMzA5NDJMOC42MDU1IDMuMDA3MUw3Ljg2NDIxIDIuMzA5NDJDNy4xNDI3MiAxLjYzMDM3IDYuMTY0MTcgMS4yNDg4OSA1LjE0MzgzIDEuMjQ4ODlDNC4xMjM0OSAxLjI0ODg5IDMuMTQ0OTQgMS42MzAzNyAyLjQyMzQ1IDIuMzA5NDJDMS43MDE5NiAyLjk4ODQ3IDEuMjk2NjMgMy45MDk0NiAxLjI5NjYzIDQuODY5NzhDMS4yOTY2MyA1LjgzMDEgMS43MDE5NiA2Ljc1MTA5IDIuNDIzNDUgNy40MzAxNEwzLjE2NDc0IDguMTI3ODJMOC42MDU1IDEzLjI0ODVMMTQuMDQ2MyA4LjEyNzgyTDE0Ljc4NzUgNy40MzAxNEMxNS4xNDQ5IDcuMDkzOTYgMTUuNDI4NCA2LjY5NDgyIDE1LjYyMTggNi4yNTU1QzE1LjgxNTIgNS44MTYxOSAxNS45MTQ3IDUuMzQ1MzEgMTUuOTE0NyA0Ljg2OTc4QzE1LjkxNDcgNC4zOTQyNSAxNS44MTUyIDMuOTIzMzcgMTUuNjIxOCAzLjQ4NDA2QzE1LjQyODQgMy4wNDQ3NCAxNS4xNDQ5IDIuNjQ1NiAxNC43ODc1IDIuMzA5NDJWMi4zMDk0MloiIHN0cm9rZT0iIzExMTAwRCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==" -->
                <!-- class="inline-block"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNyAxNCIgZmlsbD0iYmxhY2siIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMTQuNzg3NSAyLjMwOTQyQzE0LjQzMDQgMS45NzMwOSAxNC4wMDYzIDEuNzA2MjkgMTMuNTM5NSAxLjUyNDI2QzEzLjA3MjcgMS4zNDIyMyAxMi41NzI0IDEuMjQ4NTQgMTIuMDY3MiAxLjI0ODU0QzExLjU2MTkgMS4yNDg1NCAxMS4wNjE2IDEuMzQyMjMgMTAuNTk0OCAxLjUyNDI2QzEwLjEyODEgMS43MDYyOSA5LjcwMzk3IDEuOTczMDkgOS4zNDY3OCAyLjMwOTQyTDguNjA1NSAzLjAwNzFMNy44NjQyMSAyLjMwOTQyQzcuMTQyNzIgMS42MzAzNyA2LjE2NDE3IDEuMjQ4ODkgNS4xNDM4MyAxLjI0ODg5QzQuMTIzNDkgMS4yNDg4OSAzLjE0NDk0IDEuNjMwMzcgMi40MjM0NSAyLjMwOTQyQzEuNzAxOTYgMi45ODg0NyAxLjI5NjYzIDMuOTA5NDYgMS4yOTY2MyA0Ljg2OTc4QzEuMjk2NjMgNS44MzAxIDEuNzAxOTYgNi43NTEwOSAyLjQyMzQ1IDcuNDMwMTRMMy4xNjQ3NCA4LjEyNzgyTDguNjA1NSAxMy4yNDg1TDE0LjA0NjMgOC4xMjc4MkwxNC43ODc1IDcuNDMwMTRDMTUuMTQ0OSA3LjA5Mzk2IDE1LjQyODQgNi42OTQ4MiAxNS42MjE4IDYuMjU1NUMxNS44MTUyIDUuODE2MTkgMTUuOTE0NyA1LjM0NTMxIDE1LjkxNDcgNC44Njk3OEMxNS45MTQ3IDQuMzk0MjUgMTUuODE1MiAzLjkyMzM3IDE1LjYyMTggMy40ODQwNkMxNS40Mjg0IDMuMDQ0NzQgMTUuMTQ0OSAyLjY0NTYgMTQuNzg3NSAyLjMwOTQyVjIuMzA5NDJaIiBzdHJva2U9IiMxMTEwMEQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=" -->
                 <!-- class="inline-block"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xOS40Nzk0IDEuNjY3NDhMOS43Mzk3OSAxMC44MzQxIiBzdHJva2U9IiMxMTEwMEQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTE5LjQ3OTQgMS42Njc0OEwxMy4yODE1IDE4LjMzNDFMOS43Mzk3OSAxMC44MzQxTDEuNzcxMDQgNy41MDA4MUwxOS40Nzk0IDEuNjY3NDhaIiBzdHJva2U9IiMxMTEwMEQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=" -->
                <base-button
                  v-else
                  @click="addFavourite"
                  class="mr-3 mt-3 float-left clear-both md:clear-none"
                  theme="flat-white-border-sm"
                  width="width-auto-m"
                >
                  <img
                    class="heartImg"
                    :src="heart" 
                  />
                  {{
                    text[currentLanguage]["finder-button-favourites-add"]
                  }}</base-button
                >
                <base-button
                  v-show="true"
                  class="mr-3 mt-3 float-left clear-both md:clear-none"
                  theme="flat-white-border-sm"
                  width="width-auto-m"
                  @click="handleShare"
                >
                  <img
                    class="inline-block"
                    :src="iconShareBut"
                  />
                  {{
                    text[currentLanguage]["finder-button-share"]
                  }}</base-button
                >
                <base-button
                  v-show="threeSixtyAvailable"
                  class="mr-3 mt-3 float-left clear-both md:clear-none"
                  theme="flat-white-border-sm"
                  width="width-auto-m"
                  @click="handleShare"
                >
                  <img
                    class="inline-block"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNyAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMy4yODEzIDcuMjkxNzVDNy4xNzE4OCA3LjI5MTc1IDIuMjEzNTUgOS42MjUwOCAyLjIxMzU1IDEyLjUwMDFDMi4yMTM1NSAxNC44MzM0IDUuNDY3NDUgMTYuODAyMiA5Ljk2MDk0IDE3LjQ2ODhWMjAuODMzNEwxNC4zODggMTYuNjY2N0w5Ljk2MDk0IDEyLjUwMDFWMTUuMzQzOEM2LjQ3NDYxIDE0Ljc2MDUgNC40MjcwOSAxMy4zNjQ3IDQuNDI3MDkgMTIuNTAwMUM0LjQyNzA5IDExLjM5NTkgNy43OTE2NyA5LjM3NTA4IDEzLjI4MTMgOS4zNzUwOEMxOC43NzA4IDkuMzc1MDggMjIuMTM1NCAxMS4zOTU5IDIyLjEzNTQgMTIuNTAwMUMyMi4xMzU0IDEzLjI2MDUgMjAuNTE5NSAxNC40Njg4IDE3LjcwODMgMTUuMTM1NVYxNy4yNzA5QzIxLjYxNTIgMTYuNDY4OCAyNC4zNDkgMTQuNjM1NSAyNC4zNDkgMTIuNTAwMUMyNC4zNDkgOS42MjUwOCAxOS4zOTA2IDcuMjkxNzUgMTMuMjgxMyA3LjI5MTc1WiIgZmlsbD0iIzExMTAwRCIvPg0KPC9zdmc+DQo="
                  />
                  360
                </base-button>
              </div>
            </div>
          </div>

          <div class="col-span-5 details-container">
            <div class="detailsLine flex flex-row justify-between">
              <h3>area</h3>
              <div class="h3r">{{ unit.sqm }} sqm / {{ Number(unit.sqft).toLocaleString() }} sqft</div>
            </div>

            <!-- <div class="detailsLine flex flex-row justify-between">
              <h3>{{text[currentLanguage]["finder-table-type"]}}</h3>
              <div class="h3r">{{ unit.property_type.name }}</div>
            </div> -->

            <div class="detailsLine flex flex-row justify-between">
              <h3>{{text[currentLanguage]["finder-table-aspect"]}}</h3>
              <div class="h3r">{{ unit.property_aspect.name }}</div>
            </div>

            <div class="detailsLine flex flex-row justify-between">
              <h3> {{ text[currentLanguage]["finder-table-floor"] }}</h3>
              <div class="h3r">{{ unit.property_floors[0].name }}</div>
            </div>

            <div class="detailsLine flex flex-row justify-between">
              <h3> {{ text[currentLanguage]["finder-label-bedroom"] }}</h3>
              <div class="h3r">{{ unit.property_bedroom.name }}</div>
            </div>

            <div class="detailsLine flex flex-row justify-between">
               <h3> {{ text[currentLanguage]["finder-label-bathroom"] }}</h3>
              <div class="h3r">{{ unit.property_bathroom.name }}</div>
            </div>

            <div class="detailsLine flex flex-row justify-between">
               <h3> {{ text[currentLanguage]["finder-label-status"] }}</h3>
              <div class="h3r">{{ unit.property_status.name }}</div>
            </div>

            <div class="blueLine"></div>
          </div>
        </div>
      </base-content>

      <!-- https://stackoverflow.com/questions/36449523/how-can-i-slide-the-sidebar-out-of-view-and-make-the-content-flexbox-resize-to-f -->
      <!-- dimensions box v.4 --->

      <div
        v-if="$mq == 'sm' && unit.floorplans.length > 0"
        class="dimContainer2 h-full w-full"
      >
        <div v-if="!showDims" class="dimPicBox">
          <img
            :src="unit.floorplans[0].images.large"
            class="floorplan pl-8 pr-8 pt-16 pb-16"
          />
        </div>
        <div class="v-else">
          <div class="dimInfoBox w-full">
            <div
              v-if="showDims"
              class="flex flex-col pl-6 pr-6 pt-4 pb-4 dimBox"
            >
              <div class="grid grid-cols-5 pt-12">
                <div class="dimDescTop col-span-1">Dimensions</div>

                <button
                  v-if="dimMetric"
                  class="
                    dimDescTop
                    col-span-1 col-start-4
                    text-right
                    border-t
                    font-bold
                    pr-3
                  "
                  @click="dimChange(true)"
                >
                   {{ text[currentLanguage]["metricText"] }}
                </button>
                <button
                  v-if="dimMetric"
                  class="
                    dimDescTop
                    col-span-1 col-start-5
                    text-right
                    font-normal
                  "
                  @click="dimChange(false)"
                >
                   {{ text[currentLanguage]["imperialText"] }}
                </button>

                <button
                  v-if="!dimMetric"
                  class="
                    dimDescTop
                    col-span-1 col-start-4
                    text-right
                    font-normal
                    pr-3
                  "
                  @click="dimChange(true)"
                >
                  {{ text[currentLanguage]["metricText"] }}
                </button>
                <button
                  v-if="!dimMetric"
                  class="
                    dimDescTop
                    col-span-1 col-start-5
                    text-right
                    border-t
                    font-bold
                  "
                  @click="dimChange(false)"
                >
                  {{ text[currentLanguage]["imperialText"] }}
                </button>
              </div>

              <div class="border-t-2"></div>

              <div v-if="!dimMetric">
                <div
                  class="dimNum"
                  v-for="thing in unitDimensions"
                  :key="thing.id"
                  :value="thing.id"
                >
                  <div class="flex flex-row justify-between">
                    <div>{{ thing.name }}</div>
                    <div>{{ thing.i }}</div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="dimNum"
                  v-for="thing in unitDimensions"
                  :key="thing.id"
                  :value="thing.id"
                >
                  <div class="flex flex-row justify-between">
                    <div>{{ thing.name }}</div>
                    <div>{{ thing.m }}</div>
                  </div>
                </div>
              </div>

              <div class="border-t-2"></div>

              <div v-if="dimMetric" class="dimNum">
                <div class="flex flex-row justify-between">
                  <div>
                    {{ text[currentLanguage]["Total_floor_area_string"] }}
                  </div>
                  <div>{{ unit.sqm + " sqm" }}</div>
                </div>
              </div>
              <div v-else class="dimNum">
                <div class="flex flex-row justify-between">
                  <div>
                    {{ text[currentLanguage]["Total_floor_area_string"] }}
                  </div>
                  <div>{{ unit.sqft + " sqft" }}</div>
                </div>
              </div>

              <div class="border-t-2"></div>

              <div class="dimDisclaimer" v-html="text[currentLanguage]['disclaimer']"></div>

              <div
                class="flex flex-col flex-grow justify-end text-right"
                @click="doDims()"
              >
                <div class="flex flex-row justify-end pb-3">
                  <button class="dimClose"> {{ text[currentLanguage]["closeDims"] }} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- dimensions box v.3   unit.floorplans.length > 0   v-if="windowWidth >768 --->

      <div
        v-if="$mq != 'sm' && unit.floorplans.length > 0"
        class="dimContainer2 flex flex-row w-full justify-between"
      >
        <div class="dimPicBox flex flex-grow justify-center">
          <img
            :src="unit.floorplans[0].images.large"
            class="floorplan pl-8 pr-8 pt-12 pb-12"
          />
        </div>

        <div class="dimInfoBox flex-grow:0">
          <div
            v-if="showDims"
            class="flex flex-col pl-6 pr-6 pt-4 pb-4 dimBox md:pl-20 md:pr-20"
          >
            <div class="grid grid-cols-5 pt-12">
              <div class="dimDescTop col-span-1"> {{ text[currentLanguage]["dimensionsText"] }} </div>

              <button
                v-if="dimMetric"
                class="
                  dimDescTop
                  col-span-1 col-start-4
                  text-right
                  border-t
                  font-bold
                  pr-3
                "
                @click="dimChange(true)"
              >
                Metric
              </button>
              <button
                v-if="dimMetric"
                class="dimDescTop col-span-1 col-start-5 text-right font-normal"
                @click="dimChange(false)"
              >
                Imperial
              </button>

              <button
                v-if="!dimMetric"
                class="
                  dimDescTop
                  col-span-1 col-start-4
                  text-right
                  font-normal
                  pr-3
                "
                @click="dimChange(true)"
              >
                Metric
              </button>
              <button
                v-if="!dimMetric"
                class="
                  dimDescTop
                  col-span-1 col-start-5
                  text-right
                  border-t
                  font-bold
                "
                @click="dimChange(false)"
              >
                Imperial
              </button>
            </div>

            <div class="border-t-2"></div>

            <div v-if="!dimMetric">
              <div
                class="dimNum"
                v-for="thing in unitDimensions"
                :key="thing.id"
                :value="thing.id"
              >
                <div class="flex flex-row justify-between">
                  <div>{{ thing.name }}</div>
                  <div>{{ thing.i }}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="dimNum"
                v-for="thing in unitDimensions"
                :key="thing.id"
                :value="thing.id"
              >
                <div class="flex flex-row justify-between">
                  <div>{{ thing.name }}</div>
                  <div>{{ thing.m }}</div>
                </div>
              </div>
            </div>

            <div class="border-t-2"></div>

            <div v-if="dimMetric" class="dimNum">
              <div class="flex flex-row justify-between">
                <div>
                  {{ text[currentLanguage]["Total_floor_area_string"] }}
                </div>
                <div>{{ unit.sqm + " sqm" }}</div>
              </div>

              <!-- <div
                v-if="unit.property_super_stores[0].data.data2"
                class="flex flex-row justify-between"
              >
                <div>
                  {{ text[currentLanguage]["Total_outside_area_string"] }}
                </div>
                <div>
                  {{
                    unit.property_super_stores[0].data.data2[0].outside_met +
                    " sqm"
                  }}
                </div>
              </div> -->
            </div>
            <div v-else class="dimNum">
              <div class="flex flex-row justify-between">
                <div>
                  {{ text[currentLanguage]["Total_floor_area_string"] }}
                </div>
                <div>{{ unit.sqft + " sqft" }}</div>
              </div>

              <!-- <div
                v-if="unit.property_super_stores[0].data.data2"
                class="flex flex-row justify-between"
              >
                <div>
                  {{ text[currentLanguage]["Total_outside_area_string"] }}
                </div>
                <div>
                  {{
                    unit.property_super_stores[0].data.data2[0].outside_imp +
                    " sqft"
                  }}
                </div>
              </div>-->
            </div> 

            <div class="border-t-2"></div>

           <div class="dimDisclaimer" v-html="text[currentLanguage]['disclaimer']"></div>

            <div
              class="flex flex-col flex-grow justify-end text-right"
              @click="doDims()"
            >
              <div class="flex flex-row justify-end pb-3">
                <button class="dimClose"> {{ text[currentLanguage]["closeDims"] }}  </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showDims && unitDimensions" @click="doDims()">
        <button class="dimOpen"> {{ text[currentLanguage]["dimensionsText"] }} </button>
      </div>

      <div v-else>
        <button class="dimOpen2">  {{ text[currentLanguage]["dimensionsText"] }}  </button>
      </div>

      <base-content>
        <div class="specsHolder grid grid-cols-1 gap-x-8 md:grid-cols-3">
          <div class="col-span-1">
            <div
              class="specsTitle"
              v-html="text[currentLanguage]['specs_general_title'+specsSuffix]"
            ></div>
            <div
              class="pt-4 pb-10"
              v-html="text[currentLanguage]['specs_general'+specsSuffix]"
            ></div>
          </div>

          <div class="col-span-1">
            <div
              class="specsTitle"
              v-html="text[currentLanguage]['specs_kitchen_title'+specsSuffix]"
            ></div>
            <div
              class="pt-4 pb-10"
              v-html="text[currentLanguage]['specs_kitchen'+specsSuffix]"
            ></div>
          </div>

          <div class="col-span-1">
            <div
              class="specsTitle"
              v-html="text[currentLanguage]['specs_bathrooms_title'+specsSuffix]"
            ></div>
            <div
              class="pt-4"
              v-html="text[currentLanguage]['specs_bathrooms'+specsSuffix]"
            ></div>
            <div
              class="specsTitle pt-10"
              v-html="text[currentLanguage]['specs_bedrooms_title'+specsSuffix]"
            ></div>
            <div
              class="pt-4"
              v-html="text[currentLanguage]['specs_bedrooms'+specsSuffix]"
            ></div>
          </div>
        </div>
      </base-content>

      <base-gallery :galleryCategoryString="unitGallery"></base-gallery>  <!-- unitGallery     :key="number"     galleryCategoryString="gallery-interior"   -->    
      <div class="bookingBox text-white py-10">
        <base-content>
          <div class="mb-12 pt-10">
            <h2
              class="text-center mb-16"
              style="color: var(--main-key-color); text-transform: uppercase"
            >
              {{ text[currentLanguage]["booking-label-title"] }}
            </h2>
             <!-- @booking="saveBooking" -->
            <form-booking
              theme="light"
              button="flat-white"
              :text="text"
            ></form-booking>
          </div>
        </base-content>
      </div>
      <the-footer theme="dark"></the-footer>

     
    </div>
  </div>
</template>

<script>
import FormBooking from "../components/forms/FormBooking.vue";
import iconBackBut from "../assets/iconBackBut.svg";
import iconHeartStroke from "../assets/iconHeartStroke.svg";
import iconHeartFill from "../assets/iconHeartFill.svg";
import iconShare from "../assets/iconShare.svg";

export default {
  name: "FinderUnit",
  components: {
    FormBooking,
  },
  props: ["id"],
  data() {
    return {
      splashPicString: "",
      sendBooking: false,
      showShare: false,
      fpSwitch: true,
      scrollContainer: { top: 0, left: 0, x: 0, y: 0 },
      showDims: false,
      galleryVisible: false,
      galleryIndex: 2,
      viewAvailable: false,
      threeSixtyAvailable: false,
      dimMetric: false,
    };
  },
  emits: ["update"],
  computed: {
    iconShareBut() {
      return iconShare;
    },
     iconShareBut2() {
      return iconShare;
    },
    heart() {
      return iconHeartStroke;
    },
    heartSolid() {
      return iconHeartFill;
    },
    backButImg() {
      return iconBackBut;//"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0wLjI5Mjg5NiA3LjI5Mjg5Qy0wLjA5NzYyNTcgNy42ODM0MiAtMC4wOTc2MjU3IDguMzE2NTggMC4yOTI4OTYgOC43MDcxMUw2LjY1Njg2IDE1LjA3MTFDNy4wNDczOCAxNS40NjE2IDcuNjgwNTUgMTUuNDYxNiA4LjA3MTA3IDE1LjA3MTFDOC40NjE2IDE0LjY4MDUgOC40NjE2IDE0LjA0NzQgOC4wNzEwNyAxMy42NTY5TDIuNDE0MjIgOEw4LjA3MTA3IDIuMzQzMTVDOC40NjE2IDEuOTUyNjIgOC40NjE2IDEuMzE5NDYgOC4wNzEwNyAwLjkyODkzMkM3LjY4MDU1IDAuNTM4NDA4IDcuMDQ3MzggMC41Mzg0MDggNi42NTY4NiAwLjkyODkzMkwwLjI5Mjg5NiA3LjI5Mjg5Wk00Ny41MDE3IDdMMSA3VjlMNDcuNTAxNyA5VjdaIiBmaWxsPSIjMTExMDBEIi8+DQo8L3N2Zz4NCg==";
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    isFavourite() {
      const favourites = this.$store.getters["units/favourites"];
      return favourites.some((favourite) => favourite[this.unit.id]);
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
    currentPage() {
      return window.location.href;
    },
    unit() {
      return this.$store.getters["units/units"](0, "_1AR")[this.id];
    },
    unitDimensions() {
      if(Object.values(this.unit.property_super_stores)[0])
      {
         return Object.values(this.unit.property_super_stores)[0].data;
      }else{
          return null;
      }
       
    },
    unitDesc() {
      if(Object.values(this.unit.property_super_stores)[1])
      {
         return Object.values(this.unit.property_super_stores)[1].data[0].name;
      }else{
          return null;
      }
       
    },
     unitGallery() {
      if(Object.values(this.unit.property_super_stores)[1])
      {
         return Object.values(this.unit.property_super_stores)[1].data[0].gallery;
      }else{
          return "gallery-interior";
      }
       
    },
    unitSplash() {
      if(Object.values(this.unit.property_super_stores)[1])
      {
         return Object.values(this.unit.property_super_stores)[1].data[0].unitSplash;
      }else{
          return "unitDetails_splash";
      }
       
    }, 
    unitSplashMobile() {
      if(Object.values(this.unit.property_super_stores)[1])
      {
         return Object.values(this.unit.property_super_stores)[1].data[0].unitSplashMobile;
      }else{
          return "unitDetails_splash_mobile_flat";
      }
    },
    specsSuffix() {

      if(Object.values(this.unit.property_super_stores)[1])
      {
        return Object.values(this.unit.property_super_stores)[1].data[0].unitSpecsSuffix;
         
      }else{
          return "";
      }
    }
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  mounted() {
 
    this.$emit("update", true);

    this.$store.dispatch("events/event", {
      type: 1,
      id: this.id,
    });

    if (this.unit.property_view_images.length > 0) this.viewAvailable = true;

    this.splashPicString = "";

    if (this.unit.property_category.name.toLowerCase().indexOf("house") != -1) {
      //console.log(">houe"+  this.unit.property_category.name+"<");
      this.splashPicString = "_house";
    }
  },
  methods: {
    
    dimChange(i) {
      this.dimMetric = i;
    },
    showGallery(i) {
      this.galleryIndex = i;
      this.galleryVisible = true;
    },
    hideGallery() {
      this.galleryVisible = false;
    },
    galleryButHit(dir) {
      var g = document.getElementById("gallery");
      g.scrollLeft = g.scrollLeft + 50 * dir;
    },
    doDims() {
      this.showDims = !this.showDims;

      //--- if MOBILE

      if (this.$mq == "sm") {
        if (this.showDims) {
          document.getElementsByClassName("dimInfoBox")[0].style =
            "margin-left:0;";
        } else {
          document.getElementsByClassName("dimInfoBox")[0].style =
            "margin-left:20rem;";
        }
      } else {
        //--- if DESKTOP

        if (this.showDims) {
          document.getElementsByClassName("dimInfoBox")[0].style =
            "margin-right:0;";
        } else {
          //document.getElementsByClassName("dimInfoBox")[0].style =
          //  "margin-right:-12rem;";
          document.getElementsByClassName("dimInfoBox")[0].style =
            "margin-right:-1rem;";
        }
      }
    },
    formatPrice(value) {
      let val = value;//(value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    hover(event, img) {
      event.target.children[0].src = img;
    },
    floor() {
      let floorStr = "";
      this.unit.property_floors.forEach(function (floor) {
        floorStr += floor.name + ", ";
      });
      return (floorStr = floorStr.slice(0, -2));
    },
    view() {
      let viewStr = "";
      this.unit.property_views.forEach(function (view) {
        viewStr += view.name + ", ";
      });
      return (viewStr = viewStr.slice(0, -2));
    },
    addFavourite() {
      const unit = {};
      unit[this.unit.id] = this.unit;
      this.$store.dispatch("units/addFavourite", unit);
      this.$store.dispatch("events/event", {
        type: 2,
        id: this.unit.id,
      });
    },
    removeFavourite() {
      this.$store.dispatch("units/removeFavourite", this.unit.id);
    },
    handleShare() {
      this.showShare = !this.showShare;
    },
    goBack() {
      this.$router.go(-1);
    },
    copyLink() {
      var copyText = this.$refs.currentPage;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
    },
    handleFpSwitch() {
      this.fpSwitch = !this.fpSwitch;
    },
 
  },
  watch: {
    sendBooking(state) {
      if (state) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  
  },
};
</script>

<style scoped>

.userdetails{
  z-index: 2;
}

.heartImg{
 filter: var(--main-filterColDark); 
 display: inline-block;
 height:0.85rem;
}

.backArrowDiv{
   filter: var(--main-filterColDark); 
}

.dimBoxTrans-enter-active {
  transition: all 0.3s;
}

.dimBoxTrans-leave-active {
  transition: all 0.3s;
}

.dimBoxTrans-enter-from {
  transform: translateX(500px);
}

.dimBoxTrans-leave-to {
  transform: translateX(500px);
}

.menuClose {
  position: relative;
}

.menuu-enter-active {
  transition: all 6.3s;
}

.menuu-leave-active {
  transition: all 3.3s;
}

.menuu-leave-to {
  opacity: 0;
  /* transform: translateX(5px); */
}

.menuu-leave-from {
  opacity: 1;
  /* transform: translateX(5px); */
}

.menuu-enter-from {
  opacity: 0;
  /* transform: translateX(5px); */
}

.menuu-enter-to {
  opacity: 1;
  /* transform: translateX(5px); */
}

.dimTrans1-enter-active {
  transition: all 0.3s;
}

.dimTrans2-enter-active {
  transition: all 0.3s;
}

.dimTrans1-enter-from {
  transform: translateX(-250px);
}

.dimTrans2-enter-from {
  transform: translateX(350px);
}

.priceHolder {
  font-size: 1.25rem;
}

.back-arrow {
  margin-top: 7rem;
  margin-bottom: 3rem;
}
.heading {
  margin-bottom: 3rem;
}

.headerPic {
  width: 100%;
  padding-top: 5rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 2rem;
}

h1 {
  font-size: 50px;
  line-height: 3rem;
  font-weight: 400;
  text-align: left;
  color: var(--main-key-color);
  text-transform: uppercase;
  margin-bottom: 0rem;
}

h2 {
  font-size: 40px;
  font-weight: 400;
  color: var(--main-menu-subColor);
  text-transform: capitalize;
}

h3 {
  font-size: 1.25rem;
  line-height: 28px;
  font-weight: 400;
   color: var(--main-menu-subColor);
  text-transform: capitalize;
  /* padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-top: 2px solid var(--main-key-color); */
}

h4{
   color: var(--main-menu-subColor);
}

.detailsLine {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-top: 2px solid var(--main-key-color);
  color: var(--main-key-subcolor);
}

.h3r {
  font-size: 1.25rem;
  line-height: 28px;
  font-weight: 400;
  color: var(--main-menu-subColor);
  text-transform: lowercase;
  /* padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-top: 2px solid var(--main-key-color); */
  text-transform: capitalize;
}

.blueLine {
  border-top: 2px solid var(--main-key-color);
}

.building-title {
  font-size: 2.25rem;
}

h5,
h6 {
  text-transform: uppercase;
}

h6 {
  font-size: 0.75rem;
}

.details-container {
  margin-bottom: 5rem;
}

.dimContainer2 {
  background-color: var(--main-unitdetails-fpbgcolor);
}

.dimPicBox {
  margin-left: 0;
  max-height: 50rem;
  transition: margin 0.3s ease;
}

.dimInfoBox {
  background-color:var(--main-menu-bgcolor);/*  #9e958f; */
  margin-left: 22rem;
  transition: margin 0.3s ease;
}

.dimContainer {
  background-color: #dfdad5;
}

.dimBoxTest {
  background-color: #dfdad5;
}

.dimDescTop {
  color: white;
}

.dimDesc {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
}

.dimNum {
  font-size: 18px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dimDisclaimer {
  padding-top: 1.5rem;
  line-height: 0.85rem;
  font-size: 0.55rem;
  color: white;
  /* white-space: pre; */
}

.dimNotes {
  font-size: 18px;
  padding-top: 1rem;
  color: white;
  font-size: 11px;
}

.dimBox {
  background-color: var(--main-menu-subColor);
  height: 100%;
}

.dimClose {
  font-size: 16px;
  color: rgb(255, 255, 255);
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

.dimOpen {
  font-size: 16px;
  color: var(--main-menu-subColor);
  right: calc(-70%);
  top: -60px;
  height: 35px;
  position: relative;
  border-top: 2px solid var(--main-menu-subColor);
  border-bottom: 2px solid var(--main-menu-subColor);
}

.dimOpen2 {
  font-size: 22px;
  left: 185%;
  top: -37px;
  height: 35px;
  position: relative;
}

.specsTitle {
  color: var(--main-key-color);
  text-transform: uppercase;
  font-weight: 600;
  border-bottom: 1px solid var(--main-key-color);
  padding-bottom: 0.5rem;
}

.specsHolder {
  color: #11100d;
  color: var(--main-key-subcolor);
  font-size: 0.85rem;
  font-weight: 300;
}

.gallery {
  max-height: 550px;
  flex-wrap: nowrap;
  flex-direction: row;
  display: flex;
  height: 550px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 1.5rem;
  /* margin-bottom: 5rem; */
  margin-top: 5rem;
}

.gallery img {
  margin-right: 3rem;
  height: 100%;
  width: auto;
  display: inline-block;
  max-width: unset;
}

.bookingBox {
  background-color: var(--main-menu-bgcolor);
}

@media (min-width: 720px) {
  .gallery {
    margin-left: calc((100vw - (720px - 3rem)) / 2);
  }

  .headerPic {
    padding-top: 9rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
  }

  .dimBox {
    width: 500px;
  }

  .dimOpen {
    right: calc(-85%);
  }

  .dimInfoBox {
    /* margin-right: -12rem; */
    margin-right: -1rem;
    margin-left: 0px;
    transition: margin 0.3s ease;
  }

  .dimDisclaimer {
    line-height: 1rem;
    font-size: 0.75rem;
  }
}

@media (min-width: 1140px) {
  .gallery {
    margin-left: calc((100vw - (1140px - 3rem)) / 2);
  }
}

@media (min-width: 1960px) {
  .gallery {
    margin-left: calc((100vw - (1960px - 3rem)) / 2);
    padding-bottom: 5rem;
  }
}

.floorplan {
  object-fit: contain;
  /* max-height: 35rem; */
}

.key {
  font-size: 0.75rem;
}

.share-img {
  height: 60px;
  display: inline-block;
}

.border-bottom-blue {
  border-bottom: 4px solid #3b4559;
}

.fp-btn {
  color: #3b4559;
  margin-bottom: 1rem;
  /* font-family: "Lato Black", sans-serif; */
  text-transform: uppercase;
}

.fp-btn-inactive {
  color: rgba(59, 69, 89, 0.25);
}

.copy-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font: inherit;
  border-bottom: 2px solid #ffffff;
  background-color: transparent;
  padding: 0.5rem 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

@media (min-width: 720px) {
  h1 {
    font-size: 3.25rem;
    line-height: 3rem;
    margin-bottom: -0.25rem;
  }
  .back-arrow {
    margin-top: 12rem;
    margin-bottom: 3rem;
  }
  .heading {
    margin-bottom: 6rem;
  }
  h2 {
    font-size: 3rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 3.25rem;
    line-height: 4rem;
    margin-bottom: -0.5rem;
  }
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
  h1 {
    font-size: 3.25rem;
    line-height: 5rem;
    margin-bottom: -0.75rem;
  }
}

#scroll-container {
  overflow-x: auto;
  height: 100%;
}

#scroll-container > img {
  max-width: unset;
  height: 100%;
  max-height: unset;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.move-cur {
  cursor: url("../assets/scroll_cursor_pink.svg") 84 55, auto;
}

/* scrollbars */

* {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #fff;
}
*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 0px solid #fff;
}

.circle-grey {
  position: absolute;
  top: -2%;
  right: -20%;
  width: 90%;
  z-index: -1;
}

@media (min-width: 720px) {
  .circle-grey {
    top: -3%;
    right: -10%;
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .circle-grey {
    top: -5%;
    right: -10%;
    width: 55%;
  }
}

@media (min-width: 1280px) {
  .circle-grey {
    top: -5%;
    right: -10%;
    width: 55%;
  }
}

@media (min-width: 1536px) {
  .circle-grey {
    top: -10%;
    right: -10%;
    width: 50%;
  }
}
</style>

<style>
.dim-head {
  border-bottom: 4px solid #3b4559;
  padding-bottom: 1.25rem;
  font-size: 0.75rem;
  /* font-family: "Lato Black", sans-serif; */
}

.dim-body {
  font-size: 0.75rem;
  padding: 1rem 0;
}
</style>
