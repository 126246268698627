export default {
  languages(state) {
    return state.languages;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  currentLanguage(state) {
    if (state.currentId) {
      return state.currentId;
    } else {
      return null;
    }
  }
}