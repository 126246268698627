<template>
  <div class="favourites-page h-screen flex flex-col relative" v-if="text">
   
    <base-dialog
      :show="!!showShare"
      :title="text[currentLanguage]['share-main-title']"
      @close="handleShare"
      fixed
    >
      <div class="text-center">
        <base-button
          v-if="$mq === 'sm'"
          class="mr-3 mt-3 float-left md:float-none clear-both md:clear-none"
          theme="outline-white"
          width="width-auto"
          >{{ text[currentLanguage]["share-button-whatsapp"] }}</base-button
        >
        <base-button
          v-if="$mq === 'sm'"
          class="mr-3 mt-3 float-left md:float-none clear-both md:clear-none"
          theme="outline-white"
          width="width-auto"
          >{{ text[currentLanguage]["share-button-wechat"] }}</base-button
        >
        <base-button
          theme="outline-white"
          width="width-auto"
          class="mr-3 mt-3 float-left md:float-none clear-both md:clear-none"
          >{{ text[currentLanguage]["share-button-email"] }}</base-button
        >
      </div>

      <div class="my-10 text-center">
        <input
          class="copy-input"
          v-model="currentPage"
          ref="currentPage"
          readonly
        />
      </div>
      <div class="text-center">
        <base-button
          theme="outline-white"
          width="width-100"
          @click="copyLink"
          >{{ text[currentLanguage]["share-button-copylink"] }}</base-button
        >
      </div>
    </base-dialog>
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"
    ></the-header>
    <base-page-heading
      :title="text[currentLanguage]['favourites-main-title']"
      :copy="''"
    ></base-page-heading>
    <base-content class="flex-grow z-10">
   
      <div class="flex items-center justify-center pb-20">
        <div class="flex">
          <base-button
            v-show="true"
            theme="flat-white-border"
            width="width-auto-m"
            class="mr-1.5 ml-1.5 mt-3 float-left clear-both md:clear-none"
            @click="goBack"
          >
            <img class="backArrowDiv inline-block" :src="backButImg" />
          </base-button>
          <base-button
            v-if="favourites.length > 0"
            theme="flat-white-border"
            width="width-auto-m"
            class="mr-1.5 ml-1.5 mt-3 float-left clear-both md:clear-none"
            @click="clearFavourites"
            >Reset</base-button
          >
          <base-button
            v-show="true"
            theme="flat-white-border"
            width="width-auto-m"
            class="mr-1.5 ml-1.5 mt-3 float-left clear-both md:clear-none"
            @click="handleShare"
            >Share</base-button
          >
          
        </div>
      </div>
    
      <div class="grid grid-cols-1 md:grid-cols-2 gap-28">
        <div
          :class="[
            'cursor-pointer',
            'f-item',
            'relative',
            'f-item-orange',
          ]"
          v-for="favourite in favourites"
          :key="favourite.id"
          @click="showFavourite(favourite.id)"
        >
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click.stop="removeFavourite(favourite.id)"
          >
             <img class="closeButDiv" 
              :src="closeButImg"
            />
          </button>
          <img :src="favourite.img" class="mb-5" />
          <div class="grid grid-cols-2">
            <h3 class="f-item-title">
              {{ "Plot " + favourite.name }}
            </h3>
            <div class="flex flex-col-reverse">
              <h4 class="f-item-title-building">
                {{ favourite.building_name }}
              </h4>
            </div>
          </div>
          <div class="grid grid-cols-5 details-container text-sm xl:text-base">
            <div class="text-left">
              <h3>
                {{ text[currentLanguage]["units-label-area"] }}
              </h3>
              <h3>{{ Number(favourite.sqft).toLocaleString() + " "+text[currentLanguage]["finder-table-sqft"] }} </h3>
            </div>

             <div class="text-left">
              <h3>
                {{ text[currentLanguage]["finder-table-type"] }}
              </h3>
              <h3>{{ favourite.property_type }} </h3>
            </div>

            <div class="text-left">
              <h3>
                {{ text[currentLanguage]["units-label-aspect"] }}
              </h3>
              <h3>{{ favourite.aspect }}</h3>
            </div>
            <div class="text-left">
              <h3>
                {{ text[currentLanguage]["units-label-floor"] }}
              </h3>
              <h3>{{ favourite.floor }}</h3>
            </div>
            <div class="text-left">
              <h3>
                {{ text[currentLanguage]["units-label-price"] }}
              </h3>
              <h3 v-if="favourite.show_price && favourite.price">
                {{ favourite.currency + formatPrice(favourite.price) }}
              </h3>
              <h3 v-else>-</h3>
            </div>
          </div>
        </div>
      </div>
    </base-content>
    <the-footer theme="clear"></the-footer>
  </div>
</template>

<script>

import iconBackBut from "../assets/iconBackBut.svg";
import iconCloseBut from "../assets/iconCloseBut.svg";

export default {
  name: "Favourites",
  components: {
    
  },
  data() {
    return {
      showShare: false,
    };
  },
  emits: ["update"],
  mounted() {
    this.$emit("update", true);
  },
  computed: {
   
    backButImg() {
      return iconBackBut;//"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0wLjI5Mjg5NiA3LjI5Mjg5Qy0wLjA5NzYyNTcgNy42ODM0MiAtMC4wOTc2MjU3IDguMzE2NTggMC4yOTI4OTYgOC43MDcxMUw2LjY1Njg2IDE1LjA3MTFDNy4wNDczOCAxNS40NjE2IDcuNjgwNTUgMTUuNDYxNiA4LjA3MTA3IDE1LjA3MTFDOC40NjE2IDE0LjY4MDUgOC40NjE2IDE0LjA0NzQgOC4wNzEwNyAxMy42NTY5TDIuNDE0MjIgOEw4LjA3MTA3IDIuMzQzMTVDOC40NjE2IDEuOTUyNjIgOC40NjE2IDEuMzE5NDYgOC4wNzEwNyAwLjkyODkzMkM3LjY4MDU1IDAuNTM4NDA4IDcuMDQ3MzggMC41Mzg0MDggNi42NTY4NiAwLjkyODkzMkwwLjI5Mjg5NiA3LjI5Mjg5Wk00Ny41MDE3IDdMMSA3VjlMNDcuNTAxNyA5VjdaIiBmaWxsPSIjMTExMDBEIi8+DQo8L3N2Zz4NCg==";
    },
    closeButImg() {
      return iconCloseBut;//"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0wLjI5Mjg5NiA3LjI5Mjg5Qy0wLjA5NzYyNTcgNy42ODM0MiAtMC4wOTc2MjU3IDguMzE2NTggMC4yOTI4OTYgOC43MDcxMUw2LjY1Njg2IDE1LjA3MTFDNy4wNDczOCAxNS40NjE2IDcuNjgwNTUgMTUuNDYxNiA4LjA3MTA3IDE1LjA3MTFDOC40NjE2IDE0LjY4MDUgOC40NjE2IDE0LjA0NzQgOC4wNzEwNyAxMy42NTY5TDIuNDE0MjIgOEw4LjA3MTA3IDIuMzQzMTVDOC40NjE2IDEuOTUyNjIgOC40NjE2IDEuMzE5NDYgOC4wNzEwNyAwLjkyODkzMkM3LjY4MDU1IDAuNTM4NDA4IDcuMDQ3MzggMC41Mzg0MDggNi42NTY4NiAwLjkyODkzMkwwLjI5Mjg5NiA3LjI5Mjg5Wk00Ny41MDE3IDdMMSA3VjlMNDcuNTAxNyA5VjdaIiBmaWxsPSIjMTExMDBEIi8+DQo8L3N2Zz4NCg==";
    },
    currentPage() {
      const favourites = this.$store.getters["units/favourites"];
      let retString = "";
      if(favourites.length>0)
        retString = "?f=";
      favourites.forEach(function (u) {
      const unit = u[Object.keys(u)[0]];
      //    const unit = u[Object.keys(u)[0]];
      retString+= unit.id + "+";
      });
      return window.location.origin +"/favourites" + retString;
    },
     units() {
      const units = this.$store.getters["units/units"](0);
      return units;
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
    favourites() {
      const favourites = this.$store.getters["units/favourites"];
      const floorplans = [];
      //let self = this;
      favourites.forEach(function (u, index) {
        const unit = u[Object.keys(u)[0]];
        let floorStr = "";
        unit.property_floors.forEach(function (floor) {
          floorStr += floor.name + ",";
        });
        floorStr = floorStr.slice(0, -1);
        // let viewStr = "";
        // unit.property_views.forEach(function(view) {
        //   viewStr += view.name + ",";
        // });
        // viewStr = viewStr.slice(0, -1);
        let cs = "";
        if (unit.price) cs = unit.property_currency.symbol;

        const floorplan = {
          id: unit.id,
          name: unit.name,
          type: unit.property_type.name,
          aspect: unit.property_aspect.name,//self.getViews(unit.property_views),//unit.property_aspect.name, //viewStr,
          sqm: unit.sqm,
          sqft: unit.sqft,
          floor: floorStr,
          property_type:unit.property_type.name,
          currency: cs,
          building: unit.property_building.id,
          building_name: unit.property_building.name,
          price: unit.price,
          show_price: unit.show_price,
          img: unit.floorplans[0].images.medium,
          idx: index,
        };
        floorplans.push(floorplan);
      });
      return floorplans;
    },
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  methods: {
    copyLink() {
      var copyText = this.$refs.currentPage;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
    },
   getViews(views) {

      let viewStr = "";
      views.forEach(function (view) {
        viewStr += view.name + ",";
      });
      return (viewStr = viewStr.slice(0, -1));
      
    },
    goBack() {
      this.$router.go(-1);
    },
    showFavourite(id) {
      this.$router.push({ name: "FinderUnit", params: { id: id } });
    },
    removeFavourite(id) {
      this.$store.dispatch("units/removeFavourite", id);
    },
    clearFavourites() {
      this.$store.dispatch("units/clearFavourites");
    },
    handleShare() {
      this.showShare = !this.showShare;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>

.favourites-page{
  z-index:1;
}

.closeButDiv{
   filter: var(--main-filterColDark); 
}

.backArrowDiv{
   filter: var(--main-filterColDark); 
}

.copy-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font: inherit;
  border-bottom: 2px solid #ffffff;
  background-color: transparent;
  padding: 0.5rem 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.heading {
  padding-top: 12rem;
  /* margin-bottom: 5rem; */
}
h1 {
  text-align: left;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  -webkit-text-stroke: 2px;
  -webkit-text-fill-color: rgba(59, 69, 89, 0);
  text-transform: uppercase;
}
h2 {
  font-size: 3rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 5rem;
  }
}

.img {
  height: 60px;
  display: inline-block;
}

.share-img {
  height: 60px;
  display: inline-block;
}

.details-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  z-index:1;
}

.f-item-orange .details-container {
  border-top: 2px solid var(--main-key-color);
  border-bottom: 2px solid var(--main-key-color);
}

.details-container h3 {
  text-transform: capitalize;
}

.f-item img {
  padding: 3rem;
  /* background-color: #bd3b3b; */
  /* border-radius: 5px; */
}

.f-item-orange img {
  background-color: #B6B7B7;
}
.f-item-pink img {
  background-color: #B6B7B7;
}

.f-item-title {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: -0.25rem;
  color: var(--main-key-colorDark);
}

/* .f-item-orange .f-item-title {
  color: #e94a3f;
}
.f-item-pink .f-item-title {
  color: #e64283;
} */

.f-item-title-building {
  /* text-transform: uppercase; */
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: right;
  color: #11100d;
  /* font-family: "Sharp Black", sans-serif; */
}

/* .f-item-orange .f-item-title-building {
  color: #e94a3f;
}
.f-item-pink .f-item-title-building {
  color: #e64283;
} */

.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.close img {
  height: 50px;
  width: 50px;
  padding: unset;
  background-color: unset;
  border-radius: unset;
}

@media (min-width: 1280px) {
  h1 {
    margin-bottom: -1.75rem;
  }
}

@media (min-width: 1960px) {
  .f-item img {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .close img {
    height: 50px;
    width: 50px;
    padding: unset;
    background-color: unset;
    border-radius: unset;
  }
}
</style>
