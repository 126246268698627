<template>
  <div>
    <!-- <div class="r flex-col items-center" id="r"> -->

    <div class="r grid grid-cols-1 h-screen items-center" :class="theme" id="r">
      <div class="col-span-1" id="leftCol"></div>
      <div
        class="topRightClose"
        @click="closePanel"
        v-if="themeToPass != 'blueOnWhite'"
      >
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0zMC4wOTcxIDIxLjI1ODJMMjEuMjU4MyAzMC4wOTcxIiBzdHJva2U9IiNGRUJBODEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPg0KPHBhdGggZD0iTTMwLjA5NzEgMzAuMDk3MUwyMS4yNTgzIDIxLjI1ODIiIHN0cm9rZT0iI0ZFQkE4MSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI2IDUxQzM5LjgwNzEgNTEgNTEgMzkuODA3MSA1MSAyNkM1MSAxMi4xOTI5IDM5LjgwNzEgMSAyNiAxQzEyLjE5MjkgMSAxIDEyLjE5MjkgMSAyNkMxIDM5LjgwNzEgMTIuMTkyOSA1MSAyNiA1MVoiIHN0cm9rZT0iI0ZFQkE4MSIvPg0KPC9zdmc+DQo="
        />
      </div>
      <div class="topRightClose" @click="closePanel" v-else>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTIiIHZpZXdCb3g9IjAgMCA1MiA1MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0zMC4wOTcyIDIxLjI1ODJMMjEuMjU4NCAzMC4wOTcxIiBzdHJva2U9IiNFRkY0RkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPg0KPHBhdGggZD0iTTMwLjA5NzIgMzAuMDk3MUwyMS4yNTg0IDIxLjI1ODIiIHN0cm9rZT0iI0VGRjRGRiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI2IDUxQzM5LjgwNzEgNTEgNTEgMzkuODA3MSA1MSAyNkM1MSAxMi4xOTI5IDM5LjgwNzEgMSAyNiAxQzEyLjE5MjkgMSAxIDEyLjE5MjkgMSAyNkMxIDM5LjgwNzEgMTIuMTkyOSA1MSAyNiA1MVoiIHN0cm9rZT0iI0VGRjRGRiIvPg0KPC9zdmc+DQo="
        />
      </div>

      <button
        class="botRightSubmit grid grid-cols-3"
        @click="submitForm"
        :class="theme"
      >
        <div
          class="col-span-2 inline-block text-left"
          style="vertical-align: baseline"
        >
          SUBMIT
        </div>
      </button>
    </div>

    <button
      class="botRight grid-cols-3"
      @click="openPanel"
      id="me"
      :class="theme"
    >
      <div
        :class="theme"
        class="botRightText col-span-2 inline-block text-left"
        style="vertical-align: baseline"
      >
        MAKE A BOOKING
      </div>
      <div
        class="col-span-1 inline-block text-right"
        v-if="theme == 'blueOnWhite'"
      >
        <img
          class="inline-block"
          style="vertical-align: middle"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNyAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xIDkuMzMzMjhMMjYgOS4zMzMyOCIgc3Ryb2tlPSIjMDAyOTc4IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCjxwYXRoIGQ9Ik0xNy42NjYgMTcuNjY2N0wyNS45OTkzIDkuMzMzMzJMMTcuNjY2IDAuOTk5OTg5IiBzdHJva2U9IiMwMDI5NzgiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPg0KPC9zdmc+DQo="
        />
      </div>
      <div class="col-span-1 inline-block text-right" v-else>
        <img
          class="inline-block"
          style="vertical-align: middle"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNyAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xIDkuMzMzMjhMMjYgOS4zMzMyOCIgc3Ryb2tlPSIjRUZGNEZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4NCjxwYXRoIGQ9Ik0xNy42NjYzIDE3LjY2NjdMMjUuOTk5NiA5LjMzMzMyTDE3LjY2NjMgMC45OTk5ODkiIHN0cm9rZT0iI0VGRjRGRiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+DQo8L3N2Zz4NCg=="
        />
      </div>
      <!-- <hr class="filterLine col-span-5" :class="theme" /> -->
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    theme: {
      type: String,
      required: true,
      default: "light",
    },
    visOnStart: { type: Boolean, default: true },
    hasScrolledToBottom: { type: Boolean, default: false },
  },
  data() {
    return {
      sendBooking: false,
      panelClosed: false,
      panelInt: 0,
      IS_MOBILE: false,
      themeToPass: this.theme,
    };
  },
  emits: ["goPanelOpen"],

  // watch: {
  //   hasScrolledToBottom: {
  //     handler(val, oldVal) {
  //       console.log("BP:hasScrolledToBottom" + val + " :" + oldVal);
  //       this.visBotRightBut(val)
  //     },

  //     deep: true,
  //   },
  // },
  computed: {
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
    floors() {
      return this.$store.getters["units/floors"];
    },
    text() {
      return this.$store.getters["categories/text"];
    },
  },
  mounted() {
    const toMatch = [
      "Android",
      "webOS",
      "iPhone",

      "iPod",
      "BlackBerry",
      "Windows Phone",
    ];

    this.IS_MOBILE = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });

    var self = this;

    window.onscroll = function () {
      self.scrollFunction();
    };

    if (this.visOnStart) {
      //co
    } else {
      self.scrollFunction();
    }

    // console.log("visOnStar="+this.visOnStart);
    //if(!this.visOnStart)
    //  this.visBotRightBut(false)
  },
  methods: {
    scrollFunction() {
      var self = this;

      if (
        window.innerHeight + window.pageYOffset >=
        document.documentElement.scrollHeight - 60
      ) {
        //console.log(document.documentElement.scrollHeight+"you're at the bottom of the page"+window.innerHeight  );
        self.visBotRightBut(true);
      } else {
        self.visBotRightBut(false);
      }
    },

    visBotRightBut(vis) {
      if (vis) {
        document.getElementsByClassName("botRight")[0].style.display = "block";
      } else {
        document.getElementsByClassName("botRight")[0].style.display = "none";
      }
    },

    saveBooking(data) {
      this.$store.dispatch("booking/book", data);

      this.handleSendBooking();
      this.sendBooking = true;
    },
    removeUnit(u) {
      this.$parent.onUnitClicked(u);
    },

    goFavourites() {
      this.$emit("goFavesHit");
    },

    closePanel() {
      this.$parent.PANEL_OPEN = false;
      this.panelClosed = true;

      if (this.IS_MOBILE) {
        document.getElementById("r").style.right = "-100%";
      } else {
        document.getElementById("r").style.right = "-400px";
      }

      document.getElementsByClassName("botRight")[0].style.display = "block";
      this.$parent.panelActive(false);
      // if(panelClosed)
      // {
      //   document.getElementById("r").style.right = "-400px";
      // }else{
      //   document.getElementById("r").style.right = "0px";
      // }
    },

    openPanel() {
      console.log(
        "panel open" + this.panelClosed + " f=" + this.panelInt + "<"
      );

      this.$parent.PANEL_OPEN = true;
      this.panelClosed = false;
      document.getElementById("r").style.right = "0px";
      document.getElementsByClassName("botRight")[0].style.display = "none";

      this.$parent.panelActive(true);
      this.panelInt = 1;
      // if(panelClosed)
      // {
      //   document.getElementById("r").style.right = "-400px";
      // }else{
      //   document.getElementById("r").style.right = "0px";
      // }
    },
  },
};
</script>

<style scoped>
.r {
  position: fixed;
  top: 0%;
  right: -400px;
  width: 350px;
  height: 100%;
  background-color: #eff4ff;

  padding: 40px;
  transition: all 1s ease;
}

.r.blueOnWhite {
  background-color: #feba81;
  /* background-color: #FEBA81; */
  color: #ffffff;
}

.light {
  color: #ffffff;
}

.blueOnWhite {
  color: #002978;
}

.lock {
  position: absolute;
  width: 400px;
}

.topRightClose {
  position: absolute;
  top: 3%;
  right: 5%;
  z-index: 9;
}

.botRightText {
  padding-right: 1rem;
}

.botRight {
  position: fixed;
  font-size: 16px;
  font-weight: 400;
  bottom: 60px;
  right: 60px;
  color: #2f00ff;
  font-family: "Circular Std Book";

  border-bottom: 1px solid #ffffff;
}

.botRight.blueOnWhite {
  border-bottom: 1px solid #002978;
}

hr.filterLine {
  border-top: 1px solid #ffffff;
  width: 175px;
}

hr.filterLine.blueOnWhite {
  border-top: 1px solid #002978;
  width: 175px;
}

.botRightSubmit {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  bottom: 1rem;
  right: 12%;
  color: #feba81;
  z-index: 9;
  font-family: "Circular Std Book";
  width: 150px;
  height: 30px;
  border-bottom: 1px solid #feba81;
}

.botRightSubmit.blueOnWhite {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}

hr.filterLinePeach {
  border-top: 1px solid #feba81;
  width: 100px;
}

@media (max-width: 720px) {
  .r {
    width: 100%;
    right: -100%;
  }

  .botRight {
    bottom: 20px;
    right: 20px;
  }
}
</style>
