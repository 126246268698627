export default {
  async loadCategories(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.publicApiURL + "/categories";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const images = {};
    const text = context.getters.text;

    responseData.data.forEach(function(category) {
      category.contents.forEach(function(element) {
        if (Object.prototype.hasOwnProperty.call(element, "text")) {
          if (category.name !== "units-dims") {
            text[element.language_id][element.name] = element.text.replace(
              /(?:\r\n|\r|\n)/g,
              "<br />"
            );
          } else {
            text[element.language_id][element.name] = element.text;
          }
        }

        if (Object.prototype.hasOwnProperty.call(element, "images")) {
          images[element.name] = {
            category: category.name,
            small: element.images.small,
            medium: element.images.medium,
            large: element.images.large,
            extra_large: element.images.extra_large,
            twoK: element.images["2k"],
            fourK: element.images["4k"],
            original: element.images.original,
            caption: element.caption,
            order: element.order
          };
        }
      });
    });

    context.commit("setImages", images);
    context.commit("setText", text);
    context.commit("setFetchTimestamp");
  },
};
