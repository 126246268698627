import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      infoPanel: {
        visible: true,
        page: 1,
        showStart: true
      },

      modelLoading: false,
      
      reset: true,
      status: 0,
      floor: 0,
      bedroom: 0,
      view: 0,
      building:0,
      aspect:0,

      filter:{

      reset: true,
      status: 1,
      floor: 0,
      bedroom: 0,
      view: 0,
      building:0

      },
      // filters1AR: {
      //   reset: true,
      //   status: 1,
      //   floor: 0,
      //   bedroom: 0,
      //   view: 0,
      //   building:0
      // },
      // filters1FI: {
      //   reset: true,
      //   status: 1,
      //   floor: 0,
      //   bedroom: 0,
      //   view: 0
      // }
    };
  },
  mutations,
  actions,
  getters
};