import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import authModule from './modules/auth/index.js';
import categoriesModule from './modules/categories/index.js';
import unitsModule from './modules/units/index.js';
import languagesModule from './modules/languages/index.js';
import finderModule from './modules/finder/index.js';
import bookingModule from './modules/booking/index.js';
import masterplanModule from './modules/masterplan/index.js';
import eventsModule from './modules/events/index.js';

export default createStore({
  state(){
    return {
      publicApiURL: 'https://brentwood-acres.judder.io/api/v1/public',
      privateApiURL: 'https://brentwood-acres.judder.io/api/v1',
      publicApiURLV2: 'https://brentwood-acres.judder.io/api/v2/public',

      //publicApiURL: 'https://northseven.judder.io/api/v1/public',
      //privateApiURL: 'https://northseven.judder.io/api/v1'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authModule,
    categories: categoriesModule,
    units: unitsModule,
    languages: languagesModule,
    finder: finderModule,
    booking: bookingModule,
    masterplan: masterplanModule,
    events: eventsModule
  },
  plugins: [createPersistedState()],
})
