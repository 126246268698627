export default {
  visibility(context) {
    context.commit('setVisibility');
  },
  page(context, payload) {
    context.commit('setPage', {
      payload
    });
  },
  modelLoading(context) {
    context.commit('setModelLoading');
  },
  showStart(context) {
    context.commit('setShowStart');
  },
  filter(context, payload) {
    context.commit('setFilter', {
      // scheme: payload.scheme,
      filter: payload.filter,
      value: payload.value
    });
  }
};