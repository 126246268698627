export default {
  setImages(state, payload){
    state.images = payload;
  },
  setText(state, payload){
    state.text = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
}