<template>
  <teleport to="body">
    <transition name="fade">
      <div class="modal" v-if="show">
        <div class="modal__backdrop" @click="tryClose" />
        <div v-if="show" open :class="[theme, 'modal__dialog']">
          <div class="p-0">
            <input
              type="text"
              text-align:center
              :class="['w-full', { warning: !found }]"
              v-model="searchValue"
              readonly
            />
          </div>
          <div class="grid grid-cols-3 gap-0.5 p-1">
            <button
              v-for="value in values"
              :key="value"
              @click="doKeypad(value)"
            >
              {{ value }}
            </button>
            <button @click="doKeypad(-1)">&larr;</button>
          </div>
         
          <div class="searchBut" @click="search">{{ searchTitle }}</div>
         
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      default: "peach",
    },
    values: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 4,
    },
    searchTitle: {
      type: String,
      required: true,
    },
    found: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchValue: null,
    };
  },
  emits: ["open", "close", "search"],
  methods: {
    doKeypad(k) {
      let keyString;

      if (this.searchValue) {
        keyString = String(this.searchValue);
      } else {
        keyString = "";
      }

      if (k == -1) {
        // delete one character
        if (keyString.length > 1) {
          keyString = keyString.substring(0, keyString.length - 1);
          this.searchValue = keyString;
        } else {
          this.searchValue = null;
        }
      } else if (keyString.length < this.limit) {
        //add numeral
        keyString += k;
        this.searchValue = keyString;
      }
    },
    tryClose() {
      this.$emit("close");
    },
    search() {
      this.$emit("search", this.searchValue);
    },
  },
  mounted() {
    this.$emit("open");
  },
  updated() {
    this.$emit("open");
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.modal__backdrop {
  background: rgba(39, 39, 39, 0.15);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
}
.modal__dialog {
  background: var(--main-key-color);
  border-radius: 0px;
  position: relative;
  width: 20rem;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  z-index: 1002;
  background-color: rgba(201, 76, 76, 0.0);
}
button {
  padding: 0.75rem 1.5rem;
  border-radius: 0px;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  font-family: "Sharp Bold", sans-serif;
  transition: all 0.3s;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  padding-top: 1rem;
}
.teal button {
  background-color: var(--main-menu-bgcolor);
  color: #fff;
}
input {
  padding: 0.75rem 1.5rem;
  border: 0px solid;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  font-family: "Sharp Bold", sans-serif;
  padding-top: 1rem;
  background-color: var(--main-menu-bgcolor);
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.searchBut{
  color: var(--main-menu-bgcolor);
  background-color: rgb(255, 255, 255);
  padding: 0.75rem 1.5rem;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
}
.teal input {
  border-color: #70b7a6;
}
.warning {
  background-color: red;
  color: #fff;
  border: none;
}

@media screen and (max-width: 992px) {
  .modal__dialog {
    width: 90%;
  }
}
</style>
