<template>
  <teleport to="body">
    <transition name="fade">
      <div class="modal" v-if="show">
        <div class="modal__backdrop" />
        <div v-if="show" open :class="[theme, 'modal__dialog']">
          <section class="modal__body">
            <slot></slot>
          </section>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: "white",
    },
  },
  computed: {
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 200;
}
.modal__backdrop {
  background: rgba(39, 39, 39, 0.15);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.modal__dialog {
  position: relative;
  width: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: #d7d2cb;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
}
@media screen and (max-width: 992px) {
  .modal__dialog {
    width: 90%;
  }
  .modal {
    top: 1;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.modal__body {
  padding: 2rem;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  backdrop-filter: blur(0);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  backdrop-filter: blur(5px);
}

.white {
  color: #fff;
}
.dark {
  color: #3b4559;
}
h2 {
  font-size: 1.25rem;
}

@media (min-width: 720px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
</style>
