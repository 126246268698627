export default {
  setLanguages(state, payload) {
    state.languages = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  setLanguage(state, payload) {
    state.currentId = payload;
  }
}