<template>
 
  <div class="listview" v-if="units && text">
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"
      class="fixed"
    ></the-header>
  
    <base-content class="list-container relative">
      <div class="grid grid-cols-10 mb-5">
        <div class="col-span-2 md:col-span-1 text-center">
          {{ text[currentLanguage]["finder-table-number"] }}
        </div>
        <div class="col-span-1 md:col-span-1 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-block"] }}
        </div>
        <div class="col-span-2 md:col-span-1 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-floor"] }}
        </div>
        <div class="col-span-2 md:col-span-1 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-aspect"] }}
        </div>
        <div class="col-span-3 md:col-span-1 text-center">
          {{ text[currentLanguage]["finder-table-sqft"] }}
        </div>
        <div class="col-span-1 md:col-span-1 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-type"] }}
        </div>
        <div class="col-span-1 md:col-span-1 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-completion"] }}
        </div>
        <div class="col-span-4 md:col-span-1 text-center">
          {{ text[currentLanguage]["finder-table-price"] }}
        </div>
        <div class="col-span-2 text-center hidden md:block">
          {{ text[currentLanguage]["finder-table-status"] }}
        </div>
      </div>
      <div
        class="grid grid-cols-10 listrow"
        :class="{ floorBreak: unit.newFloor }"
        v-for="unit in units"
        :key="unit.id"
        @click="showUnit(unit.id)"
      >
        <div class="col-span-2 md:col-span-1 text-center number">
          {{ "Plot " + unit.name }}
        </div>
        <div class="col-span-1 capitalise text-center hidden md:block">
          {{ (unit.property_building.name) }}
        </div>
        <div class="col-span-2 md:col-span-1 text-center hidden md:block">
          {{ unit.property_floors[0].name }}
        </div>
        <div class="col-span-2 md:col-span-1 text-center hidden md:block">
          {{ unit.property_aspect.name}}
        </div>
        <div class="col-span-3 md:col-span-1 text-center">{{ Number(unit.sqft).toLocaleString() }}</div>
        <div class="col-span-1 md:col-span-1 text-center hidden md:block">
          {{ unit.property_type.name }}
          <!--unit.property_bedroom.name }} -->
        </div>
        <div class="col-span-1 md:col-span-1 text-center hidden md:block">
          {{ unit.completion_date }}
        </div>
        <div
          v-if="unit.show_price"
          class="col-span-4 md:col-span-1 text-center"
        >
          {{ unit.property_currency.symbol + formatPrice(unit.price) }}
        </div>
        <div v-else class="col-span-4 md:col-span-1 text-center">-</div>
        <div class="col-span-2 text-center hidden md:block">
          {{ unit.property_status.name }}
        </div>
      </div>
    </base-content>
    <div class="finder-switch flex flex row justify-center w-full">
      <div class="flex">
        <button class="flex-auto switch-text mr-2" @click="switchView()">
          {{ text[currentLanguage]["finder-button-3d"] }}
        </button>
        <div class="flex-auto switch-text-sel ml-2">
          {{ text[currentLanguage]["finder-button-list"] }}
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  name: "FinderList",
  data() {
    return {
      mounted: true,
    };
  },
  
  computed: {
    text() {
      return this.$store.getters["categories/text"];
    },

    units() {

      if (!this.mounted) return;

      const units = this.$store.getters["units/units"](0); // , '_1AR');

      const unitsArray = [];
      Object.keys(units).forEach(function (unit) {
        unitsArray.push(units[unit]);
      });
      const sortArray = unitsArray.sort((a, b) => a.name - b.name);

      return sortArray;
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  methods: {
    decap(word) {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    },
    handleError() {
      this.error = null;
    },
    formatPrice(value) {
      let val = value;//(value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    showUnit(id) {
      //console.log("showUnit--"+id);
      this.$router.push({ name: "FinderUnit", params: { id: id } });
    },
    switchView() {
      this.$router.push("/finder/3d");
    },
    floor(floors) {
      let floorStr = "";
      floors.forEach(function (floor) {
        floorStr += floor.name + ",";
      });
      return (floorStr = floorStr.slice(0, -1));
    },

    getViews(views) {
      let viewStr = "";
      views.forEach(function (view) {
        viewStr += view.name + ",";
      });
      return (viewStr = viewStr.slice(0, -1));
    },

  },
};
</script>

<style scoped>

.listview{
  z-index:1;
}
.list-container {
  margin-top: 7rem;
   color:var(--main-menu-subColor);
   z-index:1;
}
.listrow {
  /* background: #ffffff; */
  border: 1px solid var(--main-key-color);
  /* box-shadow: 0px 0px 12px 5px rgba(59, 69, 89, 0.05); */
  /* border-radius: 50px; */
  padding: 0.75rem 0;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding-top: 1rem;
  color:var(--main-menu-subColor);
}
.listrow:hover,
.listrow:active {
  background-color: #fff;
  box-shadow: 0px 0px 12px 5px rgba(111, 58, 68, 0.15);
}
/* .number {
  font-family: "Sharp Bold", sans-serif;
} */
.floorBreak {
  margin-top: 3rem;
}

/* Finder Switch */
.finder-switch {
  position: fixed;
  bottom: 0rem;
  z-index: 50;
  background-color: #fff;
  /* left: 40%; */
  /* border-radius: 50px; */
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 40px rgba(111, 58, 68, 0.15);
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;
  margin: 0;
}
.switch-text {
  font-weight: 700;
  line-height: 36px;
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 0.25rem;
}

.switch-text-sel {
  font-weight: 700;
  line-height: 36px;
  font-size: 16px;
  text-transform: uppercase;
  padding-top: 0.25rem;
  border-top: 2px solid #272727;
  border-bottom: 2px solid #272727;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid #3b4559;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 1px;
  background-color: #3b4559;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

@media (min-width: 720px) {
  .list-container {
    margin-top: 8rem;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
</style>
