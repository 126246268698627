export default {
  images(state) {
    return state.images;
  },
  text(state) {
    return state.text;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
}