<template>

  <!-- <a v-if="imgSrc">
    
    <slot> <img :src="imgSrc" /></slot>

  </a> -->
 
  <a v-if="extlink" :href="to" :class="[theme, width]" download>
    <slot></slot>
  </a>
  <button v-else-if="!link" :class="[theme, width]">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="theme">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: true,
      default: "outline",
    },
    width: {
      type: String,
      required: false,
      default: "width-50",
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    imgSrc: {
      type: String,
      required: false,
      default: "",
    },
    extlink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  color: white;
  cursor: pointer;
  /* border-radius: 50px; */
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  /* font-family: "Sharp Bold", sans-serif; */
  
  transition: all 0.3s;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  /* padding-top: 1rem; */
}

.width-50 {
  width: 100%;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: 100%;
}

.width-auto-m {
  width: auto;
}

.flat-blue {
  background-color: #3b4559;
  color: #fff;
  border: none;
}

.flat-white {
  background-color: #fff;
  color: #3b4559;
  border: none;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  font-weight: 400;
}

.flat-white-border {
  background-color: #fff;
  color: var(--main-menu-subColor);
  border: 1px solid  var(--main-menu-subColor);
  font-weight: 400;
  text-transform: capitalize;
}

.flat-orange-border-trans {
  background-color: rgba(255, 255,255, 0.0);
  color: #FE5000;
  border: 1px solid #FE5000;
  font-weight: 400;
  padding-top:0.15rem;
  padding-bottom:0.15rem;
  text-transform: capitalize;
}

.flat-white-border-trans {
  background-color: rgba(255, 255,255, 0.1);
  color: #3b4559;
  border: 1px solid #3b4559;
  font-weight: 400;
  text-transform: capitalize;
}

.flat-white-border-sm {
  background-color: #fff;
  color: var(--main-menu-bgcolor);
  border: 1px solid var(--main-menu-bgcolor);
  font-weight: 400;
  font-size:14px;
  text-transform: capitalize;
   padding: 0.5rem 1.0rem;
}

.flat-white-pink-h {
  background-color: #fff;
  color: #3b4559;
  border: none;
}

.flat-white-orange {
  background-color: #fff;
  color: #e94a3f;
  border: none;
}

.flat-white-pink {
  background-color: #fff;
  color: #E64283;
  border: none;
}

.flat-orange {
  background-color: #e94a3f;
  color: #fff;
  border: none;
}

.flat-pink {
  background-color: #E64283;
  color: #fff;
  border: none;
}

.outline-blue {
  background-color: transparent;
  color: #3b4559;
  border: 1px solid #3b4559;
}

.outline-blue-sm {
  background-color: transparent;
  color: #3b4559;
  border: 1px solid #3b4559;
  font-size: 11px;
  /* font-family: "Sharp Medium", sans-serif; */
  padding: 0.5rem;
  padding-top: 0.7rem;
}

.outline-white {
  background-color: transparent;
  color: #fff;
  /* border: 1px solid #fff; */
  /* font-family: "Proxima Nova Light";  */
  /* font-family: "ProximaNova",sans-serif;  */
  outline: none;
  font-weight: 400;
  font-size: 22px;
  padding: 0.75rem 0rem;
  
}

.flat-blue:hover,
.flat-blue:active,
.flat-blue:focus,
.flat-white:hover,
.flat-white:active,
.flat-white:focus,
.outline-blue:hover,
.outline-blue:active,
.outline-blue:focus {
  background-color: #3b4559;
  color: #fff;
  outline: none;
}

.outline-blue-sm:hover {
  background-color: #3b4559;
  color: #fff;
  outline: none;
}

.outline-blue-sm:active,
.outline-blue-sm:focus {
  background-color: #e94a3f;
  border-color: #e94a3f;
  color: #fff;
  outline: none;
}

.outline-white:hover,
.outline-white:active,
.outline-white:focus {
  /* background-color: rgb(255, 0, 0); */
  color: #dadada;
  outline: none;
}

.flat-orange:hover,
.flat-orange:active,
.flat-orange:focus,
.flat-white-pink:hover,
.flat-white-pink:active,
.flat-white-pink:focus,
.flat-white-orange:hover,
.flat-white-orange:active,
.flat-white-orange:focus {
  background-color: #3b4559;
  color: #fff;
  outline: none;
}

.flat-white-pink-h:hover,
.flat-white-pink-h:active,
.flat-white-pink-h:focus {
  background-color: #E64283;
  color: #fff;
  outline: none;
}

.flat-pink:hover,
.flat-pink:active,
.flat-pink:focus {
  background-color: #FFF;
  color: #E64283;
  outline: none;
}

@media (min-width: 720px) {
  .width-50 {
    width: 50%;
  }
  .width-auto {
    width: auto;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1536px) {
}
</style>
