import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      units: null,
      unitsLastFetch: null,
      statuses: null,
      statusesLastFetch: null,
      floors: null,
      plotOfWeek:null,
      floorsLastFetch: null,
      bedrooms: null,
      bedroomsLastFetch: null,
      bathrooms: null,
      bathroomsLastFetch: null,
      categories: null,
      categoriesLastFetch: null,
      aspects: null,
      aspectsLastFetch: null,
      views: null,
      viewsLastFetch: null,
      buildings: null,
      buildingsLastFetch: null,
      currentUnit: {},
      favourites: [],
    };
  },
  mutations,
  actions,
  getters
};