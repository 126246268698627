<template>
  <div class="travel relative" v-if="text && images">
  
    <the-header
      :text="text"
      logoTheme="default"
      menuTheme="default"
    ></the-header>

    <div class="map-buffer" v-if="!mapLoaded">LOADING</div>

    <div id="map-container">
      <div id="zoomImage">
        <div v-if="$mq !== 'sm'" class="relative">
          <img id="map" src="../assets/journey_map.png" @load="onMapLoad" />
        </div>
        <div v-else class="relative">
          <img id="map" src="../assets/journey_map_mobile.png" @load="onMapLoad" />
        </div>
     


      </div>
    </div>


  
  <div class="finder-switch flex flex row justify-center w-full">
          <div class="map-switch-box flex bg-white">
          
            <div>
              <button class="map-switch-text" @click="goLocal()">
                {{ text[currentLanguage]["location-switch-local"] }}
              </button>
               <button class="map-switch-text" @click="goRail()">
                {{ text[currentLanguage]["location-switch-railway"] }}
              </button>
              <button
                class="map-switch-text-selected"
                @click="showLocal(false)"
              >
                {{ text[currentLanguage]["location-switch-travel"] }}
              </button>
            </div>
          </div>
        </div>
  
  </div>
</template>

<script>

import Panzoom from "@panzoom/panzoom";
export default {
  name: "Travel",
  components: {

  },
  data() {
    return {
     mapLoaded:false,
    };
  },
  computed: {
    windowWidth() {
      return window.innerWidth;
    },
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    currentLanguage() {
      return this.$store.getters["languages/currentLanguage"];
    },
  },
  beforeCreate: function () {
    document.body.className = "bg-white";
  },
  methods: {
    goLocal(){
       this.$router.push({ name: "Location"});
    },
     goRail(){
       this.$router.push({ name: "Rail"});
    },
       onMapLoad() {
      const element = document.querySelector("#zoomImage");

      let scale = 1.0;
      switch (this.$mq) {
        case "sm":
          scale = 1.0;
          break;
        case "md":
          scale = 1.0;
          break;
      }

      this.map = Panzoom(element, {
        contain: "outside",
        overflow:"show",
      });

      element.parentElement.addEventListener("wheel", this.map.zoomWithWheel);

      let panX = 0;
      let panY = 0;

      switch (this.$mq) {
        case "sm":
          panX = 0;
          panY = 550;
          break;
        case "md":
          panX = 1500;
          panY = 1000;
          break;
        case "lg":
          panX = 1500;
          panY = 1000;
          break;
      }

      var pt = { clientX: panX, clientY: panY };

      setTimeout(() => this.map.zoomToPoint(scale, pt), 1);

      this.mapLoaded = true;
    },
   
  },
  watch: {
   
  },
};
</script>

<style scoped>

.travel{
  z-index: 2;
}

.finder-switch {
  position: fixed;
   top: 4.5rem;
  z-index: 50;
  padding: 1.5rem 1rem;
  border-radius: 25px;
  pointer-events: none;
}

@media (min-width: 720px) {
  .panel-container {
    width: 350px;
  }

    .finder-switch {
  bottom: 0.5rem;
  top:unset;
  }
}

.map-switch-box {
 border-radius: 25px;
 filter: drop-shadow(3px 3px 0.25rem);
 pointer-events: auto;
}

.map-switch-text {
  font-family: "ProximaNovaLight", sans-serif;
  line-height: 36px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.5rem 1.25rem;
 
   border-radius: 25px;
   background: white;
   color:var(--main-menu-bgcolor);
}

.map-switch-text-selected {
   border-radius: 25px;
  font-family: "ProximaNovaLight", sans-serif;
  line-height: 36px;
  font-size: 15px;
  text-transform: uppercase;
 font-weight: bold;
   padding: 0.5rem 1.25rem;
  background: var(--main-menu-bgcolor);
  color:white;
}


.img-col {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

</style>
